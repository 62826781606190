import { Routes } from '@angular/router';
import { PartnerShellPage } from './shell/partner-shell.page';
import { partnerRoleGuard } from '@zvoove-market/shared';

export const partnerRoutes: Routes = [
  {
    path: 'partner',
    canActivate: [partnerRoleGuard()],
    component: PartnerShellPage,
    data: {
      breadcrumb: () => $localize`:@@shell.partner:Partner`,
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'token',
      },
      {
        path: 'token',
        data: {
          breadcrumb: () => $localize`:@@general.token:Token`,
        },
        children: [
          {
            path: '',
            loadComponent: () => import('./token-list/partner-token-list.page').then((m) => m.PartnerTokenListPage),
          },
          {
            path: 'add',
            data: {
              breadcrumb: () => $localize`:@@general.add:Hinzufügen`,
            },
            loadComponent: () => import('./token-detail/partner-token-detail.page').then((m) => m.PartnerTokenDetailPage),
          },
        ],
      },
    ],
  },
];
