import { AsyncPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import {
  httpGetProductGetProductGroupShorthandAndNameForOwnedProducts,
  httpGetServiceCatalogGetServiceCatalogCategories,
} from '@zvoove-market/api';
import { AuthService, IZvNavigationTabItem, ZvNavigationTabs } from '@zvoove-market/shared';
import { Observable, combineLatest } from 'rxjs';
import { first, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-knowledge-shell-page',
  templateUrl: './knowledge-shell.page.html',
  styleUrls: ['./knowledge-shell.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ZvNavigationTabs, RouterOutlet, AsyncPipe],
})
export class KnowledgeShellPage {
  private http = inject(HttpClient);
  private authService = inject(AuthService);

  navLinks$: Observable<IZvNavigationTabItem[]> = combineLatest([
    toObservable(this.authService.$context),
    httpGetProductGetProductGroupShorthandAndNameForOwnedProducts(this.http),
    httpGetServiceCatalogGetServiceCatalogCategories(this.http),
  ]).pipe(
    first(),
    map(([loginCtx, products, serviceCatalogCategories]) => {
      const a: IZvNavigationTabItem[] = [
        { label: $localize`:@@knowledge.faq:Fragen & Antworten`, routerLink: ['faq'], dataCy: 'navFaq' },
        { label: $localize`:@@general.contacts:Kontakte`, routerLink: ['contact'], dataCy: 'navContacts' },
      ];

      if (serviceCatalogCategories.length) {
        a.push({
          label: $localize`:@@knowledge.serviceCatalog:Dienstleistungskatalog`,
          routerLink: ['service-catalog', serviceCatalogCategories[0].shorthand],
          dataCy: 'navServiceCatalog',
          children: serviceCatalogCategories.map((serviceCatalogCategorie) => ({
            label: serviceCatalogCategorie.name,
            routerLink: ['service-catalog', serviceCatalogCategorie.shorthand],
            dataCy: `nav-Item${serviceCatalogCategorie.shorthand}`,
          })),
        });
      }

      a.push({
        label: $localize`:@@knowledge.seminarsOnlineCourses:Seminare und Onlinekurse`,
        routerLink: ['seminars'],
        dataCy: 'navSeminarsAndOnlineCourses',
      });

      if (products.length) {
        a.push({
          label: $localize`:@@knowledge.tutorialVideos:Tutorial Videos`,
          routerLink: ['tutorial-video', products[0].shorthand],
          dataCy: 'navTutorialVideos',
          children: products.map((product) => ({
            label: product.name,
            routerLink: ['tutorial-video', product.shorthand],
            dataCy: `nav-Item${product.shorthand}`,
          })),
        });
      }

      if (loginCtx.mandant?.roles.length) {
        a.push({ label: $localize`:@@general.newsletter:Newsletter`, routerLink: ['newsletter-signup'], dataCy: 'navNewsletter' });
      }

      return a;
    }),
    startWith([])
  );
}
