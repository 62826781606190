import { Routes } from '@angular/router';
import { canAccessCoreBasicsGuard } from '@zvoove-market/shared';

export const downloadRoutes: Routes = [
  {
    path: 'downloads',
    canActivate: [canAccessCoreBasicsGuard()],
    data: {
      breadcrumb: () => $localize`:@@general.downloads:Downloads`,
    },
    loadChildren: () => import('./downloads.module').then((m) => m.DownloadModule),
  },
];
