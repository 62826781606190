import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, computed, inject, input, LOCALE_ID, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatCheckbox } from '@angular/material/checkbox';
import { httpGetCustomerUserAdministrationZvooveUserIdDetails, UserAdministrationDetailsResponseDto } from '@zvoove-market/api';
import { getRoleDisplayName, ViewDataSource } from '@zvoove-market/shared';
import { ZvView } from '@zvoove/components/view';
import { tap } from 'rxjs';

@Component({
  selector: 'app-user-list-row-details',
  templateUrl: './user-list-row-details.component.html',
  styleUrls: ['./user-list-row-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ZvView, MatCheckbox],
})
export class UserListRowDetailsComponent {
  zvooveUserId = input.required<string>();
  http = inject(HttpClient);
  locale = inject(LOCALE_ID);

  showInherited = signal<boolean>(false);
  userDetails = signal<UserAdministrationDetailsResponseDto | null>(null);
  globalRoles = computed(() =>
    this.userDetails()
      ?.globalRoles.map((role) => getRoleDisplayName(role, this.locale))
      .join(', ')
  );
  customers = computed(() => {
    const details = this.userDetails();
    return details?.assignedCustomers.map((customer) => {
      const mandants = customer.assignedMandants.map((mandant) => {
        return {
          ...mandant,
          roles: mandant.roles
            ?.filter((role) => !role.isInherited || this.showInherited())
            .map((role) => getRoleDisplayName(role, this.locale))
            .join(', '),
        };
      });
      return {
        ...customer,
        roles: customer.roles
          ?.filter((role) => !role.isInherited || this.showInherited())
          .map((role) => getRoleDisplayName(role, this.locale))
          .join(', '),
        mandants: mandants,
      };
    });
  });

  vds = new ViewDataSource({
    loadTrigger$: toObservable(this.zvooveUserId),
    loadFn: (userId) =>
      httpGetCustomerUserAdministrationZvooveUserIdDetails(this.http, { route: { zvooveUserId: userId } }).pipe(
        tap((details) => {
          this.userDetails.set(details);
        })
      ),
  });
}
