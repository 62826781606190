import { Routes } from '@angular/router';
import { canAccessCoreBasicsGuard, customerRoleGuard } from '@zvoove-market/shared';
import { KnowledgeShellPage } from './shell/knowledge-shell.page';

export const knowledgeRoutes: Routes = [
  {
    path: 'knowledge',
    canActivate: [canAccessCoreBasicsGuard()],
    component: KnowledgeShellPage,
    data: {
      breadcrumb: () => $localize`:@@general.knowledge:Knowledge`,
    },
    children: [
      {
        path: '',
        redirectTo: 'faq',
        pathMatch: 'full',
      },
      {
        path: 'faq',
        data: {
          breadcrumb: () => $localize`:@@knowledge.faq:Fragen & Antworten`,
        },
        loadChildren: () => import('./faq/faq.module').then((m) => m.FaqModule),
      },
      {
        path: 'contact',
        data: {
          breadcrumb: () => $localize`:@@general.contacts:Kontakte`,
        },
        loadChildren: () => import('./contact/contact.module').then((m) => m.ContactModule),
      },
      {
        path: 'tutorial-video',
        data: {
          breadcrumb: () => $localize`:@@knowledge.tutorialVideos:Tutorial Videos`,
        },
        loadChildren: () => import('./tutorial-video/tutorial-video.module').then((m) => m.TutorialVideoModule),
      },
      {
        path: 'service-catalog/:productShorthand',
        data: {
          breadcrumb: () => $localize`:@@knowledge.serviceCatalog:Dienstleistungskatalog`,
        },
        loadComponent: () => import('./service-catalog/service-catalog.page').then((m) => m.ServiceCatalogPage),
      },
      {
        path: 'seminars',
        data: {
          breadcrumb: () => $localize`:@@knowledge.seminarsOnlineCourses:Seminare und Onlinekurse`,
        },
        loadComponent: () => import('./seminars/seminars.page').then((m) => m.SeminarsPage),
      },
      {
        path: 'newsletter-signup',
        canActivate: [customerRoleGuard()],
        data: {
          breadcrumb: () => $localize`:@@general.newsletter:Newsletter`,
        },
        loadComponent: () => import('./newsletter-signup/newsletter-signup.page').then((m) => m.NewsletterSignupPage),
      },
    ],
  },
];
