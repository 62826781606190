<zv-form [dataSource]="ds" [formGroup]="ds.form">
  <zv-card [caption]="caption()" class="app-user-detail-page__user-card">
    <div class="app-user-detail-page__user-info-wrapper">
      <zv-form-field>
        <input matInput formControlName="email" type="text" data-cy="emailInput" />
        <mat-label i18n="@@general.email">E-Mail</mat-label>
      </zv-form-field>
      @if (zvooveUserToEdit()) {
        <zv-form-field>
          <input matInput formControlName="zvooveUserId" type="text" data-cy="idInput" />
          <mat-label i18n="@@general.id">Id</mat-label>
        </zv-form-field>
      } @else {
        <div></div>
      }
      <zv-form-field>
        <input matInput formControlName="firstName" type="text" data-cy="firstNameInput" />
        <mat-label i18n="@@general.firstName">Vorname</mat-label>
      </zv-form-field>
      <zv-form-field>
        <input matInput formControlName="lastName" type="text" data-cy="lastNameInput" />
        <mat-label i18n="@@general.lastName">Nachname</mat-label>
      </zv-form-field>

      @if (isTrainingUser()) {
        <zv-form-field
          i18n-hint="@@general.overwritePasswordHint"
          hint="Hier können Sie das Passwort überschreiben. Wenn Sie das Feld leer lassen, wird es nicht geändert.">
          <input
            matInput
            id="password"
            autocomplete="new-password"
            [type]="showPassword() ? 'text' : 'password'"
            formControlName="password"
            data-cy="passwordInputTextbox" />
          <mat-label i18n="@@general.password">Passwort</mat-label>
          <button
            type="button"
            tabindex="-1"
            mat-icon-button
            matSuffix
            (click)="showPassword.set(!showPassword())"
            data-cy="passwordToggleVisibilityButton">
            <mat-icon>{{ showPassword() ? 'visibility' : 'visibility_off' }}</mat-icon>
          </button>
        </zv-form-field>
        <zv-form-field>
          <mat-checkbox formControlName="twoFactorEnabled" data-cy="twoFactorEnabledCheckbox" i18n="@@general.twoFactor">
            Zwei-Faktor-Authentifizierung
          </mat-checkbox>
        </zv-form-field>
      }

      <zv-form-field class="app-user-detail-page__grid-entire-row">
        <zv-select
          [dataSource]="globalRoleSelectDs"
          [multiple]="true"
          formControlName="globalRoles"
          data-cy="globalRolesSelect"></zv-select>
        <mat-label i18n="@@general.partnerRoles">Partner Rollen</mat-label>
      </zv-form-field>
    </div>
  </zv-card>

  <zv-header i18n-caption="@@user.ConnectedCustomers" caption="Verknüpfte Kunden"></zv-header>
  <div class="app-user-detail-page__customer-list">
    @if (form.controls.customers.length) {
      <ng-container formArrayName="customers">
        @for (customerCtrl of form.controls.customers.controls; let ci = $index; track customerCtrl) {
          <zv-card [formGroupName]="ci">
            <div class="app-user-detail-page__customer-form">
              <zv-form-field>
                <input matInput readonly formControlName="name" data-cy="customerNameInput" />
                <mat-label i18n="@@general.customer">Kunde</mat-label>
              </zv-form-field>
              <zv-form-field>
                <zv-select [dataSource]="customerCtrl.roleDs" [multiple]="true" formControlName="roles" data-cy="customerRolesSelect">
                  <ng-container *zvSelectOptionTemplate="let item">
                    <span [style.color]="item.entity?.disabled ? 'var(--zv-error)' : null">{{ item.label }}</span>
                  </ng-container>
                </zv-select>
                <mat-label i18n="@@general.roles">Rollen</mat-label>
              </zv-form-field>
              <button
                mat-icon-button
                class="app-user-detail-page__customer-remove"
                (click)="removeCustomer(ci)"
                data-cy="deleteAssociatedCustomer">
                <mat-icon>delete</mat-icon>
              </button>

              @if (customerCtrl.value.zvooveCustomerId) {
                <zv-header
                  i18n-caption="@@general.mandants"
                  caption="Mandanten"
                  i18n-description="@@user.customerRolesWillBeInheritedByMandants"
                  description="Kundenrollen werden automatisch an alle Mandanten vererbt."
                  class="app-user-detail-page__grid-entire-row">
                </zv-header>
                @if (customerCtrl.controls.mandants.length) {
                  <ng-container formArrayName="mandants">
                    @for (mandantCtrl of customerCtrl.controls.mandants.controls; let mi = $index; track mandantCtrl) {
                      <ng-container [formGroupName]="mi">
                        <zv-form-field>
                          <input matInput readonly formControlName="name" data-cy="mandantNameInput" />
                          <mat-label i18n="@@general.mandant">Mandant</mat-label>
                        </zv-form-field>
                        <zv-form-field>
                          <zv-select
                            [dataSource]="mandantCtrl.roleDs"
                            [multiple]="true"
                            formControlName="roles"
                            data-cy="mandantRolesSelect">
                            <ng-container *zvSelectOptionTemplate="let item">
                              <span [style.color]="item.entity?.disabled ? 'var(--zv-error)' : null">{{ item.label }}</span>
                            </ng-container>
                          </zv-select>
                          <mat-label i18n="@@general.roles">Rollen</mat-label>
                        </zv-form-field>
                        <button
                          mat-icon-button
                          class="app-user-detail-page__customer-remove"
                          (click)="customerCtrl.removeMandant(mi)"
                          data-cy="deleteAssociatedMandant">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </ng-container>
                    }
                  </ng-container>
                }
                @if (customerCtrl.$selectableMandants().length) {
                  <zv-form-field>
                    <zv-select
                      [dataSource]="customerCtrl.mandantDs"
                      [(value)]="customerCtrl.$mandantToAdd"
                      data-cy="mandantSelect"
                      (selectionChange)="customerCtrl.addMandant($event)"></zv-select>
                    <mat-label i18n="@@general.addMandant">Mandant hinzufügen</mat-label>
                  </zv-form-field>
                }
              }
            </div>
          </zv-card>
        }
      </ng-container>
    } @else {
      <zv-card>
        <span class="text-bold" i18n="@@user.NoConnectedCustomers">Bisher sind keine Kunden mit diesem Benutzer verknüpft.</span>
      </zv-card>
    }

    <zv-card>
      <zv-form-field>
        <zv-select
          [dataSource]="customerDs"
          [(value)]="$customerToAdd"
          data-cy="customerSelect"
          (selectionChange)="addCustomer($event)"></zv-select>
        <mat-label i18n="@@general.addCustomer">Kunde hinzufügen</mat-label>
      </zv-form-field>
    </zv-card>
  </div>

  @if (zvooveUserToEdit() && !isTrainingUser()) {
    <div zvFormSavebarButtons>
      <button mat-stroked-button (click)="forgotPassword()" class="app-user-detail-page__reset-password">
        <span i18n="@@general.resetPassword">Passwort zurücksetzen</span>
      </button>
    </div>
  }
</zv-form>
