<zv-form [dataSource]="fds">
  <zv-card [formGroup]="fds.form" i18n-caption="@@dashboardTile.header" caption="Hier können Sie eine Dashboard-Kachel verwalten.">
    <div class="app-dashboard-tile-detail-page__grid">
      <zv-form-field>
        <mat-label i18n="@@general.internalName">Interne Bezeichnung</mat-label>
        <input matInput formControlName="internalName" data-cy="nameInputTextbox" />
      </zv-form-field>

      <zv-form-field>
        <mat-label i18n="@@general.category">Kategorie</mat-label>
        <zv-select
          [dataSource]="dashboardTileCategoryDs"
          formControlName="dashboardTileCategory"
          data-cy="dashboardTileCategorySelect"></zv-select>
      </zv-form-field>

      <zv-form-field>
        <mat-label i18n="@@general.priority">Priorität</mat-label>
        <zv-select [dataSource]="priorityDs" formControlName="priority" [clearable]="false"></zv-select>
      </zv-form-field>

      <zv-form-field>
        <zv-date-time-input [matDatepicker]="von" formControlName="validFromDate" data-cy="validFromInputDate"></zv-date-time-input>
        <mat-datepicker-toggle matSuffix [for]="von"></mat-datepicker-toggle>
        <mat-datepicker #von></mat-datepicker>
        <mat-label i18n="@@general.validFrom">Gültig von</mat-label>
      </zv-form-field>

      <zv-form-field>
        <zv-date-time-input [matDatepicker]="bis" formControlName="expireDate" data-cy="validUntilInputDate"></zv-date-time-input>
        <mat-datepicker-toggle matSuffix [for]="bis"></mat-datepicker-toggle>
        <mat-datepicker #bis></mat-datepicker>
        <mat-label i18n="@@general.validUntil">Gültig bis</mat-label>
      </zv-form-field>

      <zv-form-field>
        <mat-label i18n="@@general.products">Produkte</mat-label>
        <zv-select
          [dataSource]="productDs"
          formControlName="requiredProductShorthands"
          [multiple]="true"
          data-cy="productSelect"></zv-select>
      </zv-form-field>

      <zv-form-field class="app-dashboard-tile-detail-page__grid-entire-row">
        <mat-checkbox formControlName="isWarning" i18n="@@general.warning">Warnung</mat-checkbox>
      </zv-form-field>

      <zv-form-field>
        <mat-checkbox formControlName="showOnDashboard" i18n="@@dashboardTile.showOnDashboard" data-cy="showOnDashboardCheckbox"
          >Auf dem Hub Dashboard anzeigen</mat-checkbox
        >
      </zv-form-field>

      <zv-form-field>
        <mat-checkbox formControlName="showInApi" i18n="@@general.showInApi" data-cy="showInApiCheckbox"
          >Über die Schnittstellen verfügbar machen</mat-checkbox
        >
      </zv-form-field>

      <zv-form-field [hint]="imageHint">
        <mat-label i18n="@@general.image">Bild</mat-label>
        <zv-file-input formControlName="image" [accept]="acceptedFileTypes" data-cy="imageInputFile"></zv-file-input>
      </zv-form-field>

      <ng-container formArrayName="contentPerLanguage">
        <mat-tab-group dynamicHeight="true" class="app-dashboard-tile-detail-page__grid-entire-row" animationDuration="130ms">
          @for (content of contentPerLanguage.controls; track trackByLangCode(i, content); let i = $index) {
            <mat-tab [label]="content.value.languageName" [formGroupName]="i">
              <!-- Div is needed, otherwise the button at the end of this div will mess up the tab's layout -->
              <div class="app-dashboard-tile-detail-page__grid app-dashboard-tile-detail-page__margin-top">
                <zv-form-field>
                  <mat-label i18n="@@general.title">Titel</mat-label>
                  <input matInput formControlName="title" data-cy="titleInputTextbox" />
                </zv-form-field>
                <zv-form-field>
                  <mat-label i18n="@@general.description">Beschreibung</mat-label>
                  <input matInput formControlName="description" data-cy="descriptionInputTextbox" />
                </zv-form-field>
                <zv-form-field>
                  <mat-label i18n="@@general.buttonText">Button Text</mat-label>
                  <input matInput formControlName="buttonText" data-cy="buttonTextInputTextbox" />
                </zv-form-field>
                <zv-form-field>
                  <mat-label i18n="@@general.buttonUrl">Button-Url</mat-label>
                  <input matInput formControlName="buttonLinkUrl" data-cy="buttonLinkUrlInputTextbox" />
                </zv-form-field>
                @if (i === 0) {
                  <button
                    mat-stroked-button
                    (click)="copyDefaultLangInputsToOtherInputs()"
                    [disabled]="!canEdit()"
                    data-cy="copyDefaultLangInputsToOtherInputsButton">
                    <span i18n="@@general.copyToOtherLangInputs"
                      >Setze {{ content.value.languageName }} für alle leeren Felder in anderen Sprachen</span
                    >
                  </button>
                }
              </div>
            </mat-tab>
          }
        </mat-tab-group>
      </ng-container>
    </div>
  </zv-card>
</zv-form>
