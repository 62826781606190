import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Params, RouterLink } from '@angular/router';

import { AsyncPipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { IBreadcrumb, IBreadcrumbDataSource } from './breadcrumb-data';

export interface IBreadcrumbHome {
  routerLink: string[];
  queryParams?: Params;
}

@Component({
  selector: 'zv-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [RouterLink, MatIcon, AsyncPipe],
})
export class ZvBreadcrumbComponent {
  @Input() public home: IBreadcrumbHome | null = null;
  @Input() public dataSource!: IBreadcrumbDataSource;

  public trackByLink(_index: number, item: IBreadcrumb) {
    return item.routerLink.join('_');
  }
}
