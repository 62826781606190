<zv-table [tableId]="'users'" [dataSource]="ds" [striped]="true">
  <ng-container *zvTableCustomHeader>
    <div class="app-user-list-page__filter-form" [formGroup]="filterForm">
      <zv-form-field>
        <mat-label i18n="@@general.roles">Rollen</mat-label>
        <zv-select
          formControlName="roles"
          data-cy="rolesFilterSelect"
          [dataSource]="rolesDs"
          [multiple]="true"
          (selectionChange)="changeFilter()"
          [showToggleAll]="false"></zv-select>
      </zv-form-field>
    </div>
  </ng-container>
  <ng-container *zvTableTopButtonSection>
    <button color="primary" mat-mini-fab [routerLink]="['add']" data-cy="addUserButton">
      <mat-icon>add</mat-icon>
    </button>
  </ng-container>
  <zv-table-column i18n-header="@@general.userName" header="Benutzername" [property]="'email'">
    <ng-container *zvTableColumnTemplate="let row">
      <!-- preserve the query params, so the back navigation after saving can navigate back the the same state of the table -->
      <a [routerLink]="[row.zvooveUserId]" [queryParamsHandling]="'preserve'">{{ row.email }}</a>
    </ng-container>
  </zv-table-column>
  <zv-table-column i18n-header="@@general.id" header="Id" [property]="'zvooveUserId'"></zv-table-column>
  <zv-table-column i18n-header="@@general.firstName" header="Vorname" [property]="'firstName'"></zv-table-column>
  <zv-table-column i18n-header="@@general.lastName" header="Nachname" [property]="'lastName'"></zv-table-column>
  <zv-table-column i18n-header="@@general.registration" header="Registrierung" [sortable]="false" [property]="'registrationStatus'">
    <ng-container *zvTableColumnTemplate="let row">
      @switch (row.registrationStatus) {
        @case ('Completed') {
          <mat-icon i18n-matTooltip="@@general.completed" matTooltip="Abgeschlossen" class="app-user-list-page__success-color"
            >check_circle_outline</mat-icon
          >
        }
        @case ('Invited') {
          <mat-icon i18n-matTooltip="@@general.invited" matTooltip="Eingeladen" class="app-user-list-page__highlight-color"
            >pending</mat-icon
          >
        }
        @case ('InvitationExpired') {
          <mat-icon i18n-matTooltip="@@general.invitationExpired" matTooltip="Einladung abgelaufen" class="app-user-list-page__error-color"
            >timer_off</mat-icon
          >
        }
        @default {
          <mat-icon>help_outline</mat-icon>
        }
      }
    </ng-container>
  </zv-table-column>
  <zv-table-column i18n-header="@@general.globalRoles" header="Globale Rollen" [property]="'hasGlobalRoles'">
    <ng-container *zvTableColumnTemplate="let row">
      @switch (row.hasGlobalRoles) {
        @case (true) {
          <mat-icon i18n-matTooltip="@@general.yes" matTooltip="Ja" class="app-user-list-page__success-color">check</mat-icon>
        }
        @case (false) {
          <mat-icon i18n-matTooltip="@@general.no" matTooltip="Nein" class="app-user-list-page__error-color">close</mat-icon>
        }
      }
    </ng-container>
  </zv-table-column>
  <zv-table-column i18n-header="@@general.customers" header="Kunden" [sortable]="false" [property]="'associatedCustomersCount'">
    <ng-container *zvTableColumnTemplate="let row">
      <span i18n="@@user.xAssociatedCustomers">
        {row.associatedCustomersCount, plural,
          =1 {{{row.associatedCustomersCount}} Kunde }
          =other {{{row.associatedCustomersCount}} Kunden }
        }
        verknüpft
      </span>
    </ng-container>
  </zv-table-column>
  <zv-table-row-detail>
    <ng-container *zvTableRowDetailTemplate="let item">
      <app-user-list-row-details [zvooveUserId]="item.zvooveUserId" />
    </ng-container>
  </zv-table-row-detail>
</zv-table>
