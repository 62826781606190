import { Routes } from '@angular/router';
import { zvooveEmployeeRoleGuard } from '@zvoove-market/shared';
import { ZvooveEmployeeShellPage } from './shell/zvoove-employee-shell.page';
export const zvooveEmployeeRoutes: Routes = [
  {
    path: 'internal',
    canActivate: [zvooveEmployeeRoleGuard()],
    component: ZvooveEmployeeShellPage,
    data: {
      breadcrumb: () => $localize`:@@general.internal:Intern`,
    },
    children: [
      {
        path: '',
        redirectTo: 'statistics',
        pathMatch: 'full',
      },
      {
        path: 'statistics',
        data: {
          breadcrumb: () => $localize`:@@general.statistics:Statistiken`,
        },
        loadComponent: () => import('./core-statistic/core-statistic.page').then((p) => p.CoreStasticPage),
      },
      {
        path: 'internal-reports',
        data: {
          breadcrumb: () => $localize`:@@general.reports:Reports`,
        },
        loadComponent: () => import('./internal-reports/internal-reports.page').then((p) => p.InternalReportsPage),
      },
    ],
  },
];
