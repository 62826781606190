import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BreadcrumbService } from '@zvoove-market/shared';
import { RouterOutlet } from '@angular/router';

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet],
})
export class AppComponent {
  public constructor() {
    const breadcrumbService = inject(BreadcrumbService);
    const title = inject(Title);

    breadcrumbService.dataSource.breadcrumbs$.subscribe((breadcrumbs) =>
      title.setTitle(breadcrumbs.map((x) => x.label).join(' | ') + ' | zvoove Hub')
    );
  }
}
