import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { IZvNavigationTabItem, ZvNavigationTabs } from '@zvoove-market/shared';

@Component({
  selector: 'app-benchmark-shell-page',
  templateUrl: './benchmark-shell.page.html',
  styleUrls: ['./benchmark-shell.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ZvNavigationTabs, RouterOutlet],
})
export class BenchmarkShellPage {
  navLinks: IZvNavigationTabItem[] = [
    { label: $localize`:@@benchmark.report:Benchmark-Bericht`, routerLink: ['report'], dataCy: 'navReport' },
    { label: $localize`:@@benchmark.migrationLog:Migrationsprotokoll`, routerLink: ['log'], dataCy: 'navLog' },
    { label: $localize`:@@benchmark.hmac.keyAndSecret:API-Schlüssel und HMAC-Geheimnis`, routerLink: ['hmac-key'], dataCy: 'navHmacKey' },
  ];
}
