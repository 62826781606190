import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { IZvNavigationTabItem, ZvNavigationTabs } from '@zvoove-market/shared';

@Component({
  selector: 'app-zvoove-employee-shell-page',
  templateUrl: './zvoove-employee-shell.page.html',
  styleUrls: ['./zvoove-employee-shell.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ZvNavigationTabs, RouterOutlet],
})
export class ZvooveEmployeeShellPage {
  navLinks: IZvNavigationTabItem[] = [
    {
      label: $localize`:@@general.statistics:Statistiken`,
      routerLink: ['statistics'],
      dataCy: 'navStatistics',
    },
    {
      label: $localize`:@@general.reports:Reports`,
      routerLink: ['internal-reports'],
      dataCy: 'internalReports',
    },
  ];
}
