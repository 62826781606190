import { inject } from '@angular/core';
import { Router, Routes } from '@angular/router';
import {
  AuthService,
  canAccessCustomerSupportGuard,
  ZVOOVE_CUSTOMER_SUCCESS_ROLE,
  ZVOOVE_SUPER_ADMIN_ROLE,
  zvooveCustomerSuccessRoleGuard,
  zvooveSuperAdminRoleGuard,
} from '@zvoove-market/shared';
import { CustomerSupportShellPage } from './shell/customer-support-shell.page';
import { UserDetailPage } from './user/detail/user-detail.page';
import { UserListPage } from './user/user-list.page';

const customerSupportAreaRedirectGuard = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  return (
    (authService.$context().globalRoles.includes(ZVOOVE_CUSTOMER_SUCCESS_ROLE) && router.parseUrl('/customer-support/customers')) ||
    (authService.$context().globalRoles.includes(ZVOOVE_SUPER_ADMIN_ROLE) && router.parseUrl('/customer-support/users')) ||
    router.parseUrl('/not-found')
  );
};

export const customerSupportRoutes: Routes = [
  {
    path: 'customer-support',
    canActivate: [canAccessCustomerSupportGuard()],
    component: CustomerSupportShellPage,
    data: {
      breadcrumb: () => $localize`:@@general.customerSupport:Kundenbetreuung`,
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        canActivate: [customerSupportAreaRedirectGuard],
        children: [], // either (load)component or children is required
      },
      {
        path: 'customers',
        canActivate: [zvooveCustomerSuccessRoleGuard()],
        data: {
          breadcrumb: () => $localize`:@@general.customers:Kunden`,
        },
        loadComponent: () => import('./customer/customer-list.page').then((m) => m.CustomerListPage),
      },
      {
        path: 'db-uploads',
        canActivate: [zvooveCustomerSuccessRoleGuard()],
        data: {
          breadcrumb: () => $localize`:@@general.dbUpload:Datenbank Hochladen`,
        },
        loadComponent: () => import('./db-upload/db-upload-list.page').then((m) => m.DbUploadListPage),
      },
      {
        path: 'temporary-access',
        canActivate: [zvooveCustomerSuccessRoleGuard()],
        data: {
          breadcrumb: () => $localize`:@@general.temporaryAccesses:Temporäre Zugänge`,
        },
        loadChildren: () => import('./temporary-access/temporary-access.module').then((m) => m.TemporaryAccessModule),
      },
      {
        path: 'token',
        data: {
          breadcrumb: () => $localize`:@@system.migrationToken:Migrationstoken`,
        },
        loadComponent: () => import('./token/migrate-token.page').then((m) => m.MigrateTokenPage),
      },
      {
        path: 'users',
        data: {
          breadcrumb: () => $localize`:@@general.users:Benutzer`,
        },
        canActivate: [zvooveSuperAdminRoleGuard()],
        children: [
          {
            path: '',
            component: UserListPage,
          },
          {
            path: 'add',
            data: {
              breadcrumb: () => $localize`:@@customer.inviteUser:Benutzer einladen`,
            },
            component: UserDetailPage,
          },
          {
            path: ':zvooveUserId',
            data: {
              breadcrumb: UserDetailPage,
            },
            component: UserDetailPage,
          },
        ],
      },
    ],
  },
];
