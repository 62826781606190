import { AssignmentLevel, UserDetailsResponseDto, UserInviteRequestDto, UserUpdateRequestDto } from '@zvoove-market/api';

export interface UserDetailFormData {
  userName: string;
  firstName: string | null;
  lastName: string | null;
  customerRoles: string[];
  assignedMandants: { mandantId: string; mandantRoles: string[] }[];
}

export interface UserDetailBeforeEdit {
  customerRoles: UserDetailsResponseDto['customerRoles'];
  assignedMandants: UserDetailsResponseDto['assignedMandants'];
}

export function createSaveObject(
  formData: UserDetailFormData,
  userToEdit: UserDetailBeforeEdit | null
): UserUpdateRequestDto & UserInviteRequestDto {
  if (!userToEdit) {
    userToEdit = {
      customerRoles: [],
      assignedMandants: [],
    };
  }

  const dto: UserUpdateRequestDto & UserInviteRequestDto = {
    userName: formData.userName,
    firstName: formData.firstName,
    lastName: formData.lastName,
    rolesToAdd: calculateRolesToAdd(formData, userToEdit),
    rolesToRemove: calculateRolesToRemove(formData, userToEdit),
  };
  return dto;
}

function calculateRolesToAdd(formData: UserDetailFormData, userToEdit: UserDetailBeforeEdit) {
  const rolesToAdd = [];
  for (const role of formData.customerRoles) {
    if (!userToEdit.customerRoles.some((r) => r === role)) {
      rolesToAdd.push({ internalName: role, target: AssignmentLevel.customer, mandantId: null });
    }
  }

  for (const formDataMandant of formData.assignedMandants) {
    const oldMandantRoles = userToEdit.assignedMandants.find((m) => m.mandantId === formDataMandant.mandantId)?.mandantRoles ?? [];
    for (const formDataRole of formDataMandant.mandantRoles) {
      if (!oldMandantRoles.includes(formDataRole)) {
        rolesToAdd.push({ internalName: formDataRole, target: AssignmentLevel.mandant, mandantId: formDataMandant.mandantId });
      }
    }
  }

  return rolesToAdd;
}

function calculateRolesToRemove(formData: UserDetailFormData, userToEdit: UserDetailBeforeEdit) {
  const rolesToRemove = [];
  for (const role of userToEdit.customerRoles) {
    if (!formData.customerRoles.includes(role)) {
      rolesToRemove.push({ internalName: role, target: AssignmentLevel.customer, mandantId: null });
    }
  }

  for (const oldMandant of userToEdit.assignedMandants) {
    const formDataMandantRoles = formData?.assignedMandants.find((m) => m.mandantId === oldMandant.mandantId)?.mandantRoles ?? [];
    for (const oldMandantRole of oldMandant.mandantRoles) {
      if (!formDataMandantRoles.includes(oldMandantRole)) {
        rolesToRemove.push({
          internalName: oldMandantRole,
          target: AssignmentLevel.mandant,
          mandantId: oldMandant.mandantId,
        });
      }
    }
  }
  return rolesToRemove;
}
