import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Injector, LOCALE_ID, OnDestroy, computed, inject, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatInput, MatLabel } from '@angular/material/input';
import { MatSelectChange, MatSuffix } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import {
  UserAdministrationDetailsResponseDto,
  httpGetCustomerUserAdministrationAllRoles,
  httpGetCustomerUserAdministrationZvooveUserIdDetails,
  httpGetUserGetAllCustomers,
  httpPostCustomerUserAdministrationInvite,
  httpPostCustomerUserAdministrationZvooveUserIdUpdate,
  httpPostCustomerUserAdministrationZvooveUserIdUpdateTrainingUserLogin,
  httpPostUserForgotPassword,
} from '@zvoove-market/api';
import {
  BreadcrumbLabel,
  ConfirmDialog,
  ConfirmDialogDataIn,
  DialogWrapperDataOut,
  FormDataSource,
  noDuplicateKeysValidator,
  passwordValidator,
} from '@zvoove-market/shared';
import { ZvCard } from '@zvoove/components/card';
import { ZvForm } from '@zvoove/components/form';
import { ZvFormField } from '@zvoove/components/form-field';
import { ZvHeaderModule } from '@zvoove/components/header';
import { DefaultZvSelectDataSource, ZvSelect, ZvSelectLoadTrigger, ZvSelectOptionTemplate } from '@zvoove/components/select';
import { AutoFormArray } from '@zvoove/components/utils';
import { BehaviorSubject, Observable, combineLatest, concat, filter, last, map, of, startWith, tap } from 'rxjs';
import { createSaveObject } from './create-save-object';
import { CustomerFormGroup } from './customer-form-group';

@Component({
  selector: 'app-user-detail-page',
  templateUrl: './user-detail.page.html',
  styleUrls: ['./user-detail.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatLabel,
    ZvFormField,
    ZvForm,
    ZvHeaderModule,
    MatCardModule,
    ZvCard,
    MatIcon,
    MatCheckbox,
    ZvSelect,
    ZvSelectOptionTemplate,
    ReactiveFormsModule,
    MatInput,
    MatSuffix,
    MatButtonModule,
  ],
})
export class UserDetailPage implements OnDestroy {
  private http = inject(HttpClient);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private dialog = inject(MatDialog);

  locale = inject(LOCALE_ID);
  injector = inject(Injector);
  private static breadcrumbData$ = new BehaviorSubject<string | null>(null);
  form = new FormGroup({
    zvooveUserId: new FormControl(''),
    email: new FormControl('', { nonNullable: true, validators: [Validators.required, Validators.email] }),
    lastName: new FormControl('', { nonNullable: true, validators: Validators.maxLength(100) }),
    firstName: new FormControl('', { nonNullable: true, validators: Validators.maxLength(100) }),
    globalRoles: new FormControl<string[]>([], { nonNullable: true }),
    password: new FormControl<string | null>(null, { nonNullable: false, validators: passwordValidator() }),
    twoFactorEnabled: new FormControl<boolean | null>(null, { nonNullable: false }),
    customers: new AutoFormArray(
      () =>
        new CustomerFormGroup(
          this.injector,
          this.http,
          this.locale,
          this.route.paramMap.pipe(map((paramMap) => paramMap.get('zvooveUserId') || undefined))
        ),
      {
        validators: [
          noDuplicateKeysValidator('zvooveCustomerId', {
            message: $localize`:@@error.customerAlreadyAssigned:Dieser Kunde wurde bereits zugewiesen.`,
          }),
        ],
      }
    ),
  });
  caption = signal<string>('');
  zvooveUserToEdit = signal<UserAdministrationDetailsResponseDto | null>(null);
  isTrainingUser = computed(() => this.zvooveUserToEdit()?.isTrainingUser);
  showPassword = signal(false);

  globalRoleSelectDs = new DefaultZvSelectDataSource({
    mode: 'id',
    idKey: 'internalName',
    labelKey: 'displayName',
    disabledKey: 'isDisabled',
    items: httpGetCustomerUserAdministrationAllRoles(this.http, { query: {} }).pipe(
      map((roles) =>
        roles.items
          .filter((role) => role.types.partner || role.types.zvoove)
          .map((role) => ({
            ...role,
            isDisabled: role.types.zvoove,
          }))
      )
    ),
  });

  customerDs = new DefaultZvSelectDataSource({
    mode: 'entity',
    idKey: 'id',
    labelKey: 'name',
    items: combineLatest([
      httpGetUserGetAllCustomers(this.http),
      this.form.controls.customers.valueChanges.pipe(startWith(this.form.controls.customers.value)),
    ]).pipe(
      map(([customers, selectedCustomers]) => {
        return customers.filter(
          (customer) => !selectedCustomers.some((selectedCustomer) => selectedCustomer.zvooveCustomerId === customer.id)
        );
      })
    ),
    loadTrigger: ZvSelectLoadTrigger.initial,
  });

  ds = new FormDataSource({
    form: this.form,
    loadTrigger$: this.route.paramMap,
    loadFn: (params) => {
      this.zvooveUserToEdit.set(null);
      const zvooveUserId = params?.get('zvooveUserId') || null;
      if (zvooveUserId) {
        this.caption.set($localize`:@@customer.editUser:Benutzer bearbeiten`);
        return httpGetCustomerUserAdministrationZvooveUserIdDetails(this.http, { route: { zvooveUserId: zvooveUserId } }).pipe(
          tap((dto) => {
            this.zvooveUserToEdit.set(dto);
            UserDetailPage.breadcrumbData$.next(dto.email);
            this.ds.form.controls.email.disable();
            this.ds.form.controls.zvooveUserId.disable();
            this.ds.form.reset({
              zvooveUserId: dto.zvooveUserId,
              email: dto.email,
              firstName: dto.firstName || '',
              lastName: dto.lastName || '',
              globalRoles: dto.globalRoles.map((role) => role.internalName),
              password: null,
              twoFactorEnabled: dto.twoFactorEnabled,
              customers: dto.assignedCustomers.map((customer) => ({
                zvooveCustomerId: customer.zvooveCustomerId,
                name: customer.name,
                roles: customer.roles.filter((r) => !r.isInherited).map((role) => role.internalName),
                mandants: customer.assignedMandants
                  .map((mandant) => ({
                    zvooveMandantId: mandant.zvooveMandantId,
                    name: mandant.name,
                    roles: mandant.roles.filter((r) => !r.isInherited).map((role) => role.internalName),
                  }))
                  .filter((m) => m.roles.length > 0),
              })),
            });
          })
        );
      }

      this.caption.set($localize`:@@customer.inviteUser:Benutzer einladen`);
      this.ds.form.controls.zvooveUserId.disable();
      return of({} as UserAdministrationDetailsResponseDto);
    },
    saveFn: (formData) => {
      const userToEdit = this.zvooveUserToEdit();
      const dto = createSaveObject(formData, userToEdit);

      if (!userToEdit) {
        return httpPostCustomerUserAdministrationInvite(this.http, { body: dto });
      }

      const observables: Observable<void>[] = [];
      observables.push(
        httpPostCustomerUserAdministrationZvooveUserIdUpdate(this.http, { route: { zvooveUserId: userToEdit.zvooveUserId }, body: dto })
      );

      if (this.isTrainingUser() && (this.ds.form.value.password !== null || this.ds.form.controls.twoFactorEnabled.dirty)) {
        observables.push(
          httpPostCustomerUserAdministrationZvooveUserIdUpdateTrainingUserLogin(this.http, {
            route: { zvooveUserId: userToEdit.zvooveUserId },
            body: {
              twoFactorEnabled: formData.twoFactorEnabled,
              newPassword: this.ds.form.value.password,
            },
          })
        );
      }
      return concat(...observables).pipe(last());
    },
    btnConfigFn: (btns) => {
      btns.save = null;
    },
    // We preserve the query params, so the list page can forward the current list state and we can navigate back to the same table state
    navigateFn: () => void this.router.navigate(['../'], { relativeTo: this.route, queryParamsHandling: 'preserve' }),
  });

  public $customerToAdd = signal<{ id: string; name: string } | null>(null);
  public addCustomer(event: MatSelectChange) {
    const customerCtrls = this.form.controls.customers;
    customerCtrls.resizeTo(customerCtrls.length + 1);
    customerCtrls.controls.at(customerCtrls.length - 1)?.patchValue({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      zvooveCustomerId: event.value.id,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      name: event.value.name,
    });
    setTimeout(() => this.$customerToAdd.set(null), 0);
  }

  public removeCustomer(index: number) {
    this.form.controls.customers.removeAt(index);
    this.form.controls.customers.markAsDirty();
  }

  public forgotPassword() {
    const email = this.zvooveUserToEdit()?.email;
    const userId = this.zvooveUserToEdit()?.zvooveUserId;
    this.dialog.open<ConfirmDialog, ConfirmDialogDataIn, DialogWrapperDataOut<void>>(ConfirmDialog, {
      data: {
        title: $localize`:@@general.resetPassword:Passwort zurücksetzen`,
        content: $localize`:@@general.confirmResetPassword:Wollen Sie wirklich das Passwort von ${email} zurücksetzen?`,
        actionFn: () => httpPostUserForgotPassword(this.http, { query: { zvooveUserId: userId } }),
      },
    });
  }

  public static getBreadcrumb(): BreadcrumbLabel | Observable<BreadcrumbLabel> {
    return UserDetailPage.breadcrumbData$.pipe(
      filter((x) => !!x),
      map((email) => `${email}`)
    );
  }

  ngOnDestroy(): void {
    UserDetailPage.breadcrumbData$.next(null);
  }
}
