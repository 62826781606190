<div class="app-customer-shell-page__header">
  <h1 class="app-customer-shell-page__caption" data-cy="pageTitle" i18n="@@shell.customerArea">Kundenbereich</h1>
  <div data-cy="pageSubTitle" i18n="@@customer.subHeadline">Sie befinden sich in Ihrem persönlichen Kundenbereich</div>
</div>

@if (navLinks$ | async; as navLinks) {
  <zv-navigation-tabs [navLinks]="navLinks">
    <router-outlet></router-outlet>
  </zv-navigation-tabs>
}
