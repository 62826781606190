<zv-table data-cy="customer-user-table" [tableId]="'customer-contacts'" [dataSource]="ds" [striped]="true">
  <ng-container *zvTableCustomHeader>
    <div class="app-customer-user-list-page__form-group" [formGroup]="filterForm">
      <zv-form-field>
        <mat-label i18n="@@general.roles">Rollen</mat-label>
        <zv-select
          formControlName="roles"
          data-cy="rolesFilterSelect"
          [dataSource]="rolesDs"
          [multiple]="true"
          (selectionChange)="changeFilter()"
          [showToggleAll]="false"></zv-select>
      </zv-form-field>
    </div>
  </ng-container>
  <ng-container *zvTableTopButtonSection>
    <button color="primary" mat-mini-fab [routerLink]="['invite']" data-cy="addUserButton">
      <mat-icon>add</mat-icon>
    </button>
  </ng-container>
  <zv-table-column i18n-header="@@general.userName" header="Benutzername" [property]="'userName'">
    <ng-container *zvTableColumnTemplate="let row">
      <a [routerLink]="[row.userId]">{{ row.userName }}</a>
    </ng-container>
  </zv-table-column>
  <zv-table-column i18n-header="@@general.firstName" header="Vorname" [property]="'firstName'"></zv-table-column>
  <zv-table-column i18n-header="@@general.lastName" header="Nachname" [property]="'lastName'"></zv-table-column>
  <zv-table-column i18n-header="@@general.rolesOnCustomerLevel" header="Unternehmensweite Rollen" [property]="'joinedRoles'">
    <ng-container *zvTableColumnTemplate="let row">
      <div class="app-customer-user-list-page__role-wrapped">{{ row.joinedCustomerRoles }}</div>
    </ng-container>
  </zv-table-column>
  <zv-table-column i18n-header="@@general.status" header="Status" [property]="'registrationStatus'" [sortable]="false">
    <ng-container *zvTableColumnTemplate="let row">
      @switch (row.registrationStatus) {
        @case ('Completed') {
          <mat-icon
            i18n-matTooltip="@@general.completed"
            matTooltip="Abgeschlossen"
            class="materion-icons-outlined app-customer-user-list-page__registration-status--complete"
            >check_circle_outline</mat-icon
          >
        }
        @case ('Invited') {
          <mat-icon
            i18n-matTooltip="@@general.invited"
            matTooltip="Eingeladen"
            class="materion-icons-outlined app-customer-user-list-page__registration-status--invited"
            >pending</mat-icon
          >
        }
        @case ('InvitationExpired') {
          <mat-icon
            i18n-matTooltip="@@general.invitationExpired"
            matTooltip="Einladung abgelaufen"
            class="materion-icons-outlined app-customer-user-list-page__registration-status--InvitationExpired"
            >timer_off</mat-icon
          >
        }
        @default {
          <mat-icon>help_outline</mat-icon>
        }
      }
    </ng-container>
  </zv-table-column>

  <zv-table-row-detail>
    <ng-container *zvTableRowDetailTemplate="let item">
      <div class="app-customer-user-list-page__row-details-container">
        @if (item.mandantRoles.length) {
          @for (mandant of item.mandantRoles; track mandant.mandantId) {
            <div class="app-customer-user-list-page__mandant-roles-container">
              <b>{{ mandant.name }}:</b>
              <span>{{ mandant.roles.join(', ') }}</span>
            </div>
          }
        } @else {
          <span i18n="@@customer.noMandantRoleAssignments">Keine Berechtigungen an Mandanten zugewiesen</span>
        }
      </div>
    </ng-container>
  </zv-table-row-detail>
</zv-table>
