import { Routes } from '@angular/router';
import { customerIsAdminOrKeyUserGuard, mandantSelectedGuard } from '@zvoove-market/shared';

export const generalRoutes: Routes = [
  {
    path: 'healthz',
    loadChildren: () => import('./healthz/healthz.module').then((m) => m.HealthzModule),
  },
  {
    path: 'imprint',
    loadChildren: () => import('./imprint/imprint.module').then((m) => m.ImprintModule),
  },
  {
    path: 'privacy-notice',
    data: {
      breadcrumb: () => $localize`:@@general.privacyPolicy:Datenschutzerklärung`,
    },
    loadChildren: () => import('./privacy-notice/privacy-notice.module').then((m) => m.PrivacyNoticeModule),
  },
  {
    path: 'licenses',
    loadChildren: () => import('./licenses/third-party-licenses.module').then((m) => m.ThirdPartyLicensesModule),
  },
  {
    path: 'temporary-support-access',
    canActivate: [mandantSelectedGuard(), customerIsAdminOrKeyUserGuard()],
    loadChildren: () => import('./temporary-support-access/temporary-support-access.module').then((m) => m.TemporarySupportAccessModule),
  },
  {
    path: 'file-not-found',
    loadChildren: () => import('./file-not-found/file-not-found.module').then((m) => m.FileNotFoundModule),
  },
  {
    path: 'mandant-select',
    loadChildren: () => import('./mandant-select/mandant-select.module').then((m) => m.MandantSelectModule),
  },
  {
    path: 'unsubscribe',
    data: {
      breadcrumb: () => $localize`:@@subscribe.unsubscribe:Abmelden`,
    },
    loadComponent: () => import('./unsubscribe/unsubscribe.page').then((c) => c.UnsubscribePage),
  },
  {
    path: '**',
    loadChildren: () => import('./page-not-found/page-not-found.module').then((m) => m.PageNotFoundModule),
  },
];
