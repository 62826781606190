import { inject } from '@angular/core';
import { Router, Routes } from '@angular/router';
import {
  ADMIN_ROLE,
  AuthService,
  BUSINESS_EXECUTIVE_ROLE,
  CUSTOMER_BASE_ACCESS_ROLE_GROUP,
  customerAdminRoleGuard,
  CustomerDbUploadGuard,
  customerRoleGuard,
  firstAdminLoginPreventLeaveGuard,
  hasPayrollGuard,
  newCustomerGuard,
} from '@zvoove-market/shared';
import { CustomerUserDetailPage } from './customer-users/detail/customer-user-detail.page';
import { CustomerUserListPage } from './customer-users/list/customer-user-list.page';
import { CustomerShellPage } from './shell/customer-shell.page';

const customerAreaRedirectGuard = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const ctx = authService.$context;
  return (
    ((ctx().mandant?.roles.includes(ADMIN_ROLE) || ctx().mandant?.roles.includes(BUSINESS_EXECUTIVE_ROLE)) &&
      router.parseUrl('/customer/detail')) ||
    (ctx().mandant?.roles.some((role) => CUSTOMER_BASE_ACCESS_ROLE_GROUP.includes(role)) && router.parseUrl('/customer/appointments')) ||
    router.parseUrl('/not-found')
  );
};

export const customerRoutes: Routes = [
  {
    path: 'customer',
    canActivate: [customerRoleGuard()],
    component: CustomerShellPage,
    data: {
      breadcrumb: () => $localize`:@@shell.customerArea:Kundenbereich`,
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        canActivate: [customerAreaRedirectGuard],
        children: [], // either (load)component or children is required
      },
      {
        path: 'detail',
        canActivate: [customerAdminRoleGuard()],
        canDeactivate: [firstAdminLoginPreventLeaveGuard()],
        data: {
          breadcrumb: () => $localize`:@@general.businessData:Unternehmensdaten`,
        },
        loadComponent: () => import('./customer/customer-detail.page').then((m) => m.CustomerDetailPage),
      },
      {
        canActivate: [customerAdminRoleGuard(), CustomerDbUploadGuard],
        path: 'db-upload',
        data: {
          breadcrumb: () => $localize`:@@general.dbUpload:Datenbank Hochladen`,
        },
        loadChildren: () => import('./customer-db-upload/customer-db-upload.module').then((m) => m.CustomerDbUploadModule),
      },
      {
        path: 'contacts',
        canActivate: [customerAdminRoleGuard()],
        data: {
          breadcrumb: () => $localize`:@@general.contacts:Kontakte`,
        },
        loadChildren: () => import('./customer-contacts/customer-contacts.module').then((m) => m.CustomerContactsModule),
      },
      {
        path: 'activities',
        canActivate: [customerAdminRoleGuard()],
        data: {
          breadcrumb: () => $localize`:@@general.customerActivityRecords:Tätigkeitsnachweise`,
        },
        loadChildren: () => import('./customer-activities/customer-activities.module').then((m) => m.CustomerActivitiesModule),
      },
      {
        path: 'users',
        canActivate: [customerAdminRoleGuard()],
        data: {
          breadcrumb: () => $localize`:@@general.users:Benutzer`,
        },
        children: [
          {
            path: '',
            component: CustomerUserListPage,
          },
          {
            path: 'invite',
            component: CustomerUserDetailPage,
            data: {
              breadcrumb: () => $localize`:@@customer.inviteUser:Benutzer einladen`,
            },
          },
          {
            path: ':userId',
            component: CustomerUserDetailPage,
            data: {
              breadcrumb: CustomerUserDetailPage,
            },
          },
        ],
      },
      {
        path: 'invoices',
        canActivate: [customerAdminRoleGuard()],
        data: {
          breadcrumb: () => $localize`:@@general.invoices:Rechnungen`,
        },
        loadComponent: () => import('./customer-orders-and-invoices/invoices/invoices.page').then((m) => m.InvoicesPage),
      },
      {
        path: 'orders',
        canActivate: [customerAdminRoleGuard()],
        data: {
          breadcrumb: () => $localize`:@@general.orders:Bestellungen`,
        },
        loadComponent: () => import('./customer-orders-and-invoices/orders/orders.page').then((m) => m.OrdersPage),
      },
      {
        path: 'email-accounts',
        canActivate: [customerAdminRoleGuard()],
        data: {
          breadcrumb: () => $localize`:@@general.emailAccounts:E-Mail Konten`,
        },
        loadChildren: () => import('./customer-email/accounts/routes').then((r) => r.CUSTOMER_EMAIL_ROUTES),
      },
      {
        path: 'email-logs',
        canActivate: [customerAdminRoleGuard()],
        data: {
          breadcrumb: () => $localize`:@@email.sentMessages:Versendete Nachrichten`,
        },
        loadChildren: () => import('./customer-email/email-logs/email-logs.module').then((m) => m.CustomerEmailLogModule),
      },
      {
        canActivate: [customerAdminRoleGuard(), hasPayrollGuard()],
        path: 'payroll-token',
        data: {
          breadcrumb: () => $localize`:@@general.payrollToken:Payrolltoken`,
        },
        loadComponent: () => import('./payroll-token/payroll-token.page').then((m) => m.PayrollTokenPage),
      },
      {
        canActivate: [customerAdminRoleGuard(), newCustomerGuard()],
        path: 'dataprecapture',
        data: {
          breadcrumb: () => $localize`:@@general.dataPreCapture:Stammdatenvorerfassung`,
        },
        loadChildren: () => import('./customer-dataprecapture/customer-dataprecapture.module').then((m) => m.CustomerDataprecaptureModule),
      },
      {
        path: 'appointments',
        data: {
          breadcrumb: () => $localize`:@@general.appointments:Termine`,
        },
        loadComponent: () => import('./appointments/appointment-list.page').then((m) => m.AppointmentListPage),
      },
      {
        path: 'token',
        data: {
          breadcrumb: () => $localize`:@@customer.pdlToken:PDL Token`,
        },
        loadChildren: () => import('./customer-token/customer-token.routes').then((m) => m.CUSTOMER_TOKEN_ROUTES),
      },
      {
        path: 'adv-contracts',
        canActivate: [customerAdminRoleGuard()],
        data: {
          breadcrumb: () => $localize`:@@customer.advContracts:ADV-Verträge`,
        },
        loadComponent: () => import('./adv-contracts/adv-contract-list.page').then((m) => m.AdvContractListPage),
      },
      {
        canActivate: [customerAdminRoleGuard()],
        path: 'partner-management',
        data: {
          breadcrumb: () => $localize`:@@general.partnerManagement:Partner Management`,
        },
        loadComponent: () => import('./partner-management/partner-management.page').then((m) => m.PartnerManagementPage),
      },
    ],
  },
];
