import { Routes } from '@angular/router';
import { hasAnalyticsGuard, devFeatureGuard } from '@zvoove-market/shared';
import { BenchmarkShellPage } from './shell/benchmark-shell.page';

export const benchmarkRoutes: Routes = [
  {
    path: 'benchmark',
    canActivate: [devFeatureGuard(), hasAnalyticsGuard()],
    component: BenchmarkShellPage,
    data: {
      breadcrumb: () => $localize`:@@benchmark.title:Benchmark`,
    },
    children: [
      {
        path: '',
        redirectTo: 'report',
        pathMatch: 'full',
      },
      {
        path: 'report',
        data: {
          breadcrumb: () => $localize`:@@benchmark.report:Benchmark-Bericht`,
        },
        loadComponent: () => import('./benchmark/benchmark.page').then((m) => m.BenchmarkPage),
      },
      {
        path: 'log',
        data: {
          breadcrumb: () => $localize`:@@benchmark.migrationLog:Migrationsprotokoll`,
        },
        loadComponent: () => import('./migration/log/migration-log.page').then((m) => m.MigrationLogPage),
      },
      {
        path: 'log/:migrationLogId',
        data: {
          breadcrumb: () => $localize`:@@benchmark.migrationRawData:Rohdaten der Migration`,
        },
        loadComponent: () => import('./migration/rawdata/migration-rawdata.page').then((m) => m.MigrationRawDataPage),
      },
      {
        path: 'hmac-key',
        data: {
          breadcrumb: () => $localize`:@@benchmark.hmac.keyAndSecret:API-Schlüssel und HMAC-Geheimnis`,
        },
        loadComponent: () => import('./hmac/hmac-key.page').then((m) => m.HmacKeyPage),
      },
    ],
  },
];
