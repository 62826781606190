import { Routes } from '@angular/router';
import { canSeeContentManagementGuard, zvooveContentManagerRoleGuard } from '@zvoove-market/shared';
import { DashboardTileDetailPage } from './dashboard-tile/detail/dashboard-tile-detail.page';
import { ExternalVideoDetailPage } from './external-video/detail/external-video-detail.page';
import { ContentManagementShellPage } from './shell/content-management-shell.page';
export const contentManagementRoutes: Routes = [
  {
    path: 'content-management',
    canActivate: [canSeeContentManagementGuard()],
    component: ContentManagementShellPage,
    data: {
      breadcrumb: () => $localize`:@@general.contentManagement:Content Management`,
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'downloads',
      },
      {
        path: 'downloads',
        data: {
          breadcrumb: () => $localize`:@@general.downloads:Downloads`,
        },
        children: [
          {
            path: '',
            loadComponent: () => import('./download/list/download-list.page').then((p) => p.DownloadListPage),
          },
          {
            path: 'add',
            canActivate: [zvooveContentManagerRoleGuard()],
            loadComponent: () => import('./download/detail/download-detail.page').then((p) => p.DownloadDetailPage),
            data: {
              breadcrumb: () => $localize`:@@general.add:Hinzufügen`,
            },
          },
          {
            path: ':downloadId',
            loadComponent: () => import('./download/detail/download-detail.page').then((p) => p.DownloadDetailPage),
          },
        ],
      },
      {
        path: 'videos',
        data: {
          breadcrumb: () => $localize`:@@general.videos:Videos`,
        },
        children: [
          {
            path: '',
            loadComponent: () => import('./external-video/list/external-video-list.page').then((p) => p.ExternalVideoListPage),
          },
          {
            path: 'add',
            canActivate: [zvooveContentManagerRoleGuard()],
            loadComponent: () => import('./external-video/detail/external-video-detail.page').then((p) => p.ExternalVideoDetailPage),
            data: {
              breadcrumb: () => $localize`:@@general.add:Hinzufügen`,
            },
          },
          {
            path: ':id',
            loadComponent: () => import('./external-video/detail/external-video-detail.page').then((p) => p.ExternalVideoDetailPage),
            data: {
              breadcrumb: ExternalVideoDetailPage,
            },
          },
        ],
      },
      {
        path: 'faq-entries',
        data: {
          breadcrumb: () => $localize`:@@general.faqEntries:FAQ Einträge`,
        },
        children: [
          {
            path: '',
            loadComponent: () => import('./faq-entry/list/faq-entry-list.page').then((p) => p.FaqEntryListPage),
          },
          {
            path: 'add',
            canActivate: [zvooveContentManagerRoleGuard()],
            loadComponent: () => import('./faq-entry/detail/faq-entry-detail.page').then((p) => p.FaqEntryDetailPage),
            data: {
              breadcrumb: () => $localize`:@@general.add:Hinzufügen`,
            },
          },
          {
            path: ':entryId',
            loadComponent: () => import('./faq-entry/detail/faq-entry-detail.page').then((p) => p.FaqEntryDetailPage),
            data: {
              breadcrumb: () => $localize`:@@general.editEntry:Eintrag bearbeiten`,
            },
          },
        ],
      },
      {
        path: 'faq-categories',
        data: {
          breadcrumb: () => $localize`:@@general.faqCategories:FAQ Kategorien`,
        },
        children: [
          {
            path: '',
            loadComponent: () => import('./faq-category/list/faq-category-list.page').then((p) => p.FaqCategoryListPage),
          },
          {
            path: 'add',
            canActivate: [zvooveContentManagerRoleGuard()],
            loadComponent: () => import('./faq-category/detail/faq-category-detail.page').then((p) => p.FaqCategoryDetailPage),
            data: {
              breadcrumb: () => $localize`:@@general.add:Hinzufügen`,
            },
          },
          {
            path: ':categoryId',
            loadComponent: () => import('./faq-category/detail/faq-category-detail.page').then((p) => p.FaqCategoryDetailPage),
            data: {
              breadcrumb: () => $localize`:@@general.editEntry:Eintrag bearbeiten`,
            },
          },
        ],
      },
      {
        path: 'faq-statistics',
        data: {
          breadcrumb: () => $localize`:@@general.faqStatistics:FAQ Statistiken`,
        },
        loadComponent: () => import('./faq-statistic/faq-statistic.page').then((p) => p.FaqStasticPage),
      },
      {
        path: 'dashboard-tiles',
        data: {
          breadcrumb: () => $localize`:@@general.dashboardTiles:Dashboard Kacheln`,
        },
        children: [
          {
            path: '',
            loadComponent: () => import('./dashboard-tile/list/dashboard-tile-list.page').then((p) => p.DashboardTileListPage),
          },
          {
            path: 'add',
            canActivate: [zvooveContentManagerRoleGuard()],
            loadComponent: () => import('./dashboard-tile/detail/dashboard-tile-detail.page').then((p) => p.DashboardTileDetailPage),
            data: {
              breadcrumb: () => $localize`:@@general.add:Hinzufügen`,
            },
          },
          {
            path: ':tileId',
            loadComponent: () => import('./dashboard-tile/detail/dashboard-tile-detail.page').then((p) => p.DashboardTileDetailPage),
            data: {
              breadcrumb: DashboardTileDetailPage,
            },
          },
        ],
      },
      {
        path: 'release-info',
        data: {
          breadcrumb: () => $localize`:@@shell.releaseInfo:Release-Informationen`,
        },
        children: [
          {
            path: '',
            loadComponent: () => import('./product-changelog/list/product-changelog-list.page').then((p) => p.ProductChangelogListPage),
          },
          {
            path: 'add',
            canActivate: [zvooveContentManagerRoleGuard()],
            loadComponent: () =>
              import('./product-changelog/detail/product-changelog-detail.page').then((p) => p.ProductChangelogDetailPage),
            data: {
              breadcrumb: () => $localize`:@@general.add:Hinzufügen`,
            },
          },
          {
            path: ':changelogId',
            loadComponent: () =>
              import('./product-changelog/detail/product-changelog-detail.page').then((p) => p.ProductChangelogDetailPage),
            data: {
              breadcrumb: () => $localize`:@@general.editEntry:Eintrag bearbeiten`,
            },
          },
        ],
      },
      {
        path: 'email-templates',
        data: {
          breadcrumb: () => $localize`:@@general.emailTemplates:E-Mail Vorlagen`,
        },
        children: [
          {
            path: '',
            loadComponent: () => import('./email-template/list/email-template-list.page').then((p) => p.EmailTemplateListPage),
          },
          {
            path: ':templateId',
            loadComponent: () => import('./email-template/detail/email-template-detail.page').then((p) => p.EmailTemplateDetailPage),
            data: {
              breadcrumb: () => $localize`:@@general.editEntry:Eintrag bearbeiten`,
            },
          },
          {
            path: 'add',
            canActivate: [zvooveContentManagerRoleGuard()],
            loadComponent: () => import('./email-template/detail/email-template-detail.page').then((p) => p.EmailTemplateDetailPage),
            data: {
              breadcrumb: () => $localize`:@@general.editEntry:Eintrag bearbeiten`,
            },
          },
          {
            path: 'add',
            canActivate: [zvooveContentManagerRoleGuard()],
            loadComponent: () => import('./email-template/detail/email-template-detail.page').then((p) => p.EmailTemplateDetailPage),
            data: {
              breadcrumb: () => $localize`:@@general.add:Hinzufügen`,
            },
          },
        ],
      },
      {
        path: 'bulk',
        data: {
          breadcrumb: () => $localize`:@@general.bulkEmails:Serienmails`,
        },
        children: [
          {
            path: '',
            loadComponent: () => import('./email-template/history/bulk-mail-history.page').then((p) => p.BulkMailHistoryPage),
          },
          {
            path: 'send',
            canActivate: [zvooveContentManagerRoleGuard()],
            loadComponent: () => import('./email-template/send/bulk-mail-send.page').then((p) => p.BulkMailSendPage),
            data: {
              breadcrumb: () => $localize`:@@bulkMail.new:Neue Serienmail senden`,
            },
          },
        ],
      },
      {
        path: 'service-catalogs',
        data: {
          breadcrumb: () => $localize`:@@knowledge.serviceCatalog:Dienstleistungskatalog`,
        },
        children: [
          {
            path: '',
            loadComponent: () => import('./service-catalog/list/service-catalog-list.page').then((p) => p.ServiceCatalogListPage),
          },
          {
            path: 'add',
            canActivate: [zvooveContentManagerRoleGuard()],
            loadComponent: () => import('./service-catalog/detail/service-catalog-detail.page').then((p) => p.ServiceCatalogDetailPage),
            data: {
              breadcrumb: () => $localize`:@@general.add:Hinzufügen`,
            },
          },
          {
            path: ':id',
            loadComponent: () => import('./service-catalog/detail/service-catalog-detail.page').then((p) => p.ServiceCatalogDetailPage),
            data: {
              breadcrumb: () => $localize`:@@general.editEntry:Eintrag bearbeiten`,
            },
          },
        ],
      },
    ],
  },
];
