import { Routes } from '@angular/router';
import { canSeeShopGuard } from '@zvoove-market/shared';
import { BehaviorSubject, filter } from 'rxjs';

export const productDetailBreadcrumb$ = new BehaviorSubject<string | null>(null);

export const shopRoutes: Routes = [
  {
    path: 'shop',
    canActivate: [canSeeShopGuard()],
    data: {
      breadcrumb: () => $localize`:@@shell.shop:Shop`,
    },
    children: [
      {
        path: '',
        children: [
          {
            path: '',
            loadComponent: () => import('./product-list/product-list.page').then((m) => m.ProductListPage),
          },
          {
            path: 'cart',
            loadComponent: () => import('./cart/cart.page').then((m) => m.CartPage),
            data: {
              breadcrumb: () => $localize`:@@shell.cart:Cart`,
            },
          },
          {
            path: 'checkout-success',
            loadComponent: () => import('./checkout-success/checkout-success.page').then((m) => m.CheckoutSuccessPage),
            data: {
              breadcrumb: () => $localize`:@@shell.cart:Cart`,
            },
          },
          {
            path: ':productCode',
            loadComponent: () => import('./product-detail/product-detail.page').then((m) => m.ProductDetailPage),
            data: {
              breadcrumb: () => productDetailBreadcrumb$.pipe(filter((x) => !!x)),
            },
          },
        ],
      },
    ],
  },
];
