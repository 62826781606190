import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject, LOCALE_ID, OnDestroy, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatInput, MatLabel } from '@angular/material/input';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import {
  AssignmentLevel,
  httpGetCustomerMandantSelectList,
  httpGetCustomerUserRolesCanassign,
  httpGetCustomerUserUserIdDetails,
  httpPostCustomerUserInvite,
  httpPostCustomerUserUserIdUpdate,
  UserDetailsResponseDto,
} from '@zvoove-market/api';
import {
  ADMIN_ROLE,
  AuthService,
  BreadcrumbLabel,
  BUSINESS_EXECUTIVE_ROLE,
  FormDataSource,
  noDuplicateKeysValidator,
  requiredNotEmptyIfValidator,
} from '@zvoove-market/shared';
import { ZvCard, ZvCardTopButtonSection } from '@zvoove/components/card';
import { ZvForm } from '@zvoove/components/form';
import { ZvFormField } from '@zvoove/components/form-field';
import {
  DefaultZvSelectDataSource,
  ZvSelect,
  ZvSelectLoadTrigger,
  ZvSelectOptionTemplate,
  ZvSelectSortBy,
} from '@zvoove/components/select';
import { AutoFormArray } from '@zvoove/components/utils';
import { getAssignableRoles } from 'projects/shared/src/lib/user/role.helpers';
import { BehaviorSubject, combineLatest, filter, map, Observable, of, startWith, switchMap, tap } from 'rxjs';
import { createSaveObject } from './create-save-object';

@Component({
  selector: 'app-customer-user-detail-page',
  templateUrl: './customer-user-detail.page.html',
  styleUrls: ['./customer-user-detail.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatLabel,
    ZvFormField,
    ZvForm,
    ZvSelect,
    ZvSelectOptionTemplate,
    ZvCard,
    ZvCardTopButtonSection,
    MatIcon,
    ReactiveFormsModule,
    MatInput,
    MatButtonModule,
  ],
})
export class CustomerUserDetailPage implements OnDestroy {
  private static breadcrumbData$ = new BehaviorSubject<string | null>(null);
  private http = inject(HttpClient);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private auth = inject(AuthService);
  private locale = inject(LOCALE_ID);

  isCustomerAdmin =
    this.auth.$context().customer?.roles.includes(ADMIN_ROLE) || this.auth.$context().customer?.roles.includes(BUSINESS_EXECUTIVE_ROLE);

  userId?: string;
  zvooveUserToEdit = signal<UserDetailsResponseDto | null>(null);
  mandants: MandantRole[] = [];
  customerRoles: string[] = [];

  mandantDs = new DefaultZvSelectDataSource({
    mode: 'id',
    idKey: 'zvooveMandantId',
    labelKey: 'name',
    items: toObservable(this.auth.$context).pipe(
      switchMap((context) => {
        if (!context.customer) return of([]);
        return httpGetCustomerMandantSelectList(this.http, { query: { zvooveCustomerId: context.customer.id } }).pipe(
          map((dto) => dto.items)
        );
      })
    ),
    loadTrigger: ZvSelectLoadTrigger.initial,
  });

  ds = new FormDataSource({
    form: new FormGroup(
      {
        userName: new FormControl('', { nonNullable: true, validators: [Validators.required, Validators.email] }),
        lastName: new FormControl('', Validators.maxLength(100)),
        firstName: new FormControl('', Validators.maxLength(100)),
        customerRoles: new FormControl<string[]>([], { nonNullable: true }),
        assignedMandants: new AutoFormArray(() => this.createMandantRoleForm(), {
          validators: [
            noDuplicateKeysValidator('mandantId', {
              message: $localize`:@@error.customerAlreadyAssigned:Dieser Kunde wurde bereits zugewiesen.`,
            }),
          ],
        }),
      },
      [
        requiredNotEmptyIfValidator('customerRoles', (formGroup) => (formGroup.controls.assignedMandants as AutoFormArray).length === 0, {
          message: $localize`:@@error.mandantRolesRequired:Bitte vergeben Sie mindestens eine Rolle auf Mandant- oder Unternehmensebene.`,
        }),
      ]
    ),
    loadTrigger$: this.route.paramMap,
    loadFn: (paramMap) => {
      this.zvooveUserToEdit.set(null);
      this.userId = paramMap?.get('userId') || undefined;
      if (this.userId) {
        return httpGetCustomerUserUserIdDetails(this.http, { route: { userId: this.userId } }).pipe(
          tap((user) => {
            this.zvooveUserToEdit.set(user);
            CustomerUserDetailPage.breadcrumbData$.next(user.userName);
            if (!this.isCustomerAdmin) {
              this.customerRoles = user.customerRoles;
              user = { ...user, customerRoles: [] };
            }
            this.ds.form.reset(user);
            this.ds.form.controls.userName.disable();
          })
        );
      }

      this.ds.updateButtonDefs((btns) => {
        if (btns.saveAndClose) {
          btns.saveAndClose.label = $localize`:@@customer.inviteUser:Benutzer einladen`;
        }
      });

      CustomerUserDetailPage.breadcrumbData$.next($localize`:@@customer.inviteUser:Benutzer einladen`);
      return of({} as UserDetailsResponseDto);
    },
    saveFn: (formData) => {
      const dto = createSaveObject(formData, this.zvooveUserToEdit());
      return this.userId
        ? httpPostCustomerUserUserIdUpdate(this.http, { route: { userId: this.userId }, body: dto })
        : httpPostCustomerUserInvite(this.http, { body: dto });
    },
    navigateFn: () => {
      this.router.navigate(['../'], { relativeTo: this.route });
    },
    btnConfigFn: (btns) => {
      if (!this.userId) btns.save = null;
    },
  });

  roleSelectForCustomerDs = new DefaultZvSelectDataSource({
    mode: 'id',
    idKey: 'internalName',
    labelKey: 'displayName',
    sortBy: ZvSelectSortBy.comparer,
    loadTrigger: ZvSelectLoadTrigger.initial,
    items: combineLatest([
      this.route.paramMap.pipe(
        switchMap((paramMap) => {
          let query = undefined;
          const userId = paramMap.get('userId');
          if (userId) {
            query = { target: AssignmentLevel.customer, targetZvooveUserId: userId };
          } else {
            query = { target: AssignmentLevel.customer };
          }
          return httpGetCustomerUserRolesCanassign(this.http, { query: query });
        })
      ),
      this.ds.form.controls.customerRoles.valueChanges.pipe(startWith(this.ds.form.controls.customerRoles.value)),
    ]).pipe(map(([availableRoles, selectedRoles]) => getAssignableRoles(availableRoles.items, this.locale, selectedRoles))),
  });

  public addMandantToList() {
    const formGroup = this.createMandantRoleForm();
    this.ds.form.controls.assignedMandants.push(formGroup);
  }

  public removeMandant(index: number) {
    this.mandants.splice(index, 1);
    this.ds.form.controls.assignedMandants.removeAt(index);
    this.ds.form.controls.assignedMandants.markAsDirty();
  }

  static getBreadcrumb(): BreadcrumbLabel | Observable<BreadcrumbLabel> {
    return CustomerUserDetailPage.breadcrumbData$.pipe(
      filter((x) => !!x),
      map((userName) => `${userName}`)
    );
  }

  public ngOnDestroy(): void {
    CustomerUserDetailPage.breadcrumbData$.next(null);
  }

  private createMandantRoleForm() {
    const formGroup = new FormGroup({
      mandantId: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      mandantRoles: new FormControl<string[]>([], { nonNullable: true, validators: [Validators.required, Validators.minLength(1)] }),
    });

    const mandantRoles = new MandantRole(
      formGroup.controls.mandantId,
      formGroup.controls.mandantRoles,
      this.http,
      this.mandantDs,
      this.route.paramMap,
      this.locale
    );
    this.mandants.push(mandantRoles);

    return formGroup;
  }
}

class MandantRole {
  public roleDs: DefaultZvSelectDataSource;

  constructor(
    public mandantIdControl: FormControl<string>,
    public roleControl: FormControl<string[]>,
    private http: HttpClient,
    public mandantDs: DefaultZvSelectDataSource,
    private paramMap$: Observable<ParamMap>,
    private locale: string
  ) {
    this.roleDs = new DefaultZvSelectDataSource({
      mode: 'id',
      idKey: 'internalName',
      labelKey: 'displayName',
      items: this.mandantIdControl.valueChanges.pipe(
        startWith(mandantIdControl.value),
        // It looks weird, but this subscribes late and doesn't get the initial control value during the first load of the page.
        // So if changedCustomer is empty, this is the initial load and we shouldn't reset the roles since they are the ones already in the database for this customer.
        tap((isNotInitialLoad) => {
          if (isNotInitialLoad) this.roleControl.reset();
          return;
        }),
        switchMap(() => paramMap$),
        switchMap((paramMap) => {
          let query = undefined;
          const userId = paramMap.get('userId');
          if (userId) {
            query = { target: AssignmentLevel.mandant, targetId: mandantIdControl.value, targetZvooveUserId: userId };
          } else {
            query = { target: AssignmentLevel.mandant, targetId: mandantIdControl.value };
          }
          return mandantIdControl.value
            ? httpGetCustomerUserRolesCanassign(this.http, { query: query }).pipe(
                map((roles) => getAssignableRoles(roles.items, this.locale, roleControl.value))
              )
            : of([]);
        })
      ),
      loadTrigger: ZvSelectLoadTrigger.initial,
    });
  }
}
