<div class="zv-breadcrumb__container" data-cy="breadcrumb">
  @if (home) {
    <a class="zv-breadcrumb__link" [routerLink]="home.routerLink" [queryParams]="home.queryParams">
      <mat-icon class="zv-breadcrumb__home-icon" data-cy="breadcrumbHomeIcon">home</mat-icon>
    </a>
  }
  @if (dataSource.breadcrumbs$ | async; as breadcrumbs) {
    @for (item of breadcrumbs; track trackByLink($index, item); let first = $first) {
      @if (!first || home) {
        <span class="zv-breadcrumb__separator">&gt;</span>
      }
      <a class="zv-breadcrumb__link" [routerLink]="item.routerLink" [queryParams]="item.queryParams">
        {{ item.label }}
      </a>
    }
  }
</div>
