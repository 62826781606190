import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatLabel } from '@angular/material/input';
import { MatTooltip } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import {
  AssignmentLevel,
  DeleteResultDto,
  httpDeleteCustomerUserUserIdRemoveFromCustomer,
  httpDeleteCustomerUserUserIdRemoveFromMandant,
  httpGetCustomerUserList,
  httpGetCustomerUserRolesCanassign,
  httpPostCustomerUserUserIdResendInviteCode,
  UserListResponseDto,
} from '@zvoove-market/api';
import {
  ADMIN_ROLE,
  AuthService,
  BreadcrumbLabel,
  BUSINESS_EXECUTIVE_ROLE,
  chunked,
  DeleteService,
  DialogWrapperAction,
} from '@zvoove-market/shared';
import { ZvFormField } from '@zvoove/components/form-field';
import { DefaultZvSelectDataSource, ZvSelect } from '@zvoove/components/select';
import { IZvTableAction, ZvTableActionScope, ZvTableDataSource, ZvTableModule } from '@zvoove/components/table';
import { catchError, map, of, Subject } from 'rxjs';

@Component({
  selector: 'app-customer-user-list-page',
  templateUrl: './customer-user-list.page.html',
  styleUrls: ['./customer-user-list.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatLabel, ZvFormField, ZvTableModule, RouterLink, MatIcon, ZvSelect, ReactiveFormsModule, MatButtonModule, MatTooltip],
})
export class CustomerUserListPage implements OnDestroy {
  private http = inject(HttpClient);
  private deleteService = inject(DeleteService);
  private auth = inject(AuthService);
  public tableLoadTrigger$ = new Subject<void>();

  filterForm = new FormGroup({
    roles: new FormControl<string[]>([]),
  });

  ds = new ZvTableDataSource<UserListResponseDto>({
    loadTrigger$: this.tableLoadTrigger$,
    loadDataFn: () =>
      httpGetCustomerUserList(this.http).pipe(
        map((users) =>
          users
            .map((user) => ({
              ...user,
              joinedCustomerRoles: user.customerRoles.join(', '),
            }))
            .filter(
              (user) =>
                !this.filterForm.value.roles?.length ||
                user.customerRoles.some((role) => this.filterForm.value.roles?.includes(role)) ||
                user.mandantRoles.some((mandant) => mandant.roles.some((role) => this.filterForm.value.roles?.includes(role)))
            )
        )
      ),
    openRowMenuActionFn: (data, actions) => {
      const permissionActions: IZvTableAction<UserListResponseDto>[] = data.mandantRoles.map((mandant) => ({
        label: $localize`:@@mandant.revokePermissions:${mandant.name} entziehen`,
        icon: 'key_off',
        scope: ZvTableActionScope.list,
        actionFn: () => {
          httpDeleteCustomerUserUserIdRemoveFromMandant(this.http, {
            route: { userId: data.userId },
            query: { zvooveMandantId: mandant.mandantId },
          }).subscribe(() => this.ds.updateData());
        },
      }));

      if (permissionActions.length > 1) {
        return [
          ...actions,
          {
            label: $localize`:@@general.revokePermissions:Berechtigungen entziehen`,
            icon: 'key_off',
            scope: ZvTableActionScope.row,
            children: permissionActions,
          },
        ];
      } else if (permissionActions.length === 1) {
        return [...actions, ...permissionActions];
      }

      return actions;
    },
    actions: [
      {
        label: $localize`:@@general.resendMail:E-Mail erneut senden`,
        icon: 'email',
        scope: ZvTableActionScope.row,
        actionFn: (items: UserListResponseDto[]) => {
          httpPostCustomerUserUserIdResendInviteCode(this.http, { route: { userId: items[0].userId } }).subscribe(() =>
            this.ds.updateData()
          );
        },
        isHiddenFn: (items: UserListResponseDto[]) => items[0].registrationStatus !== 'InvitationExpired',
      },
      {
        label: $localize`:@@general.delete:Löschen`,
        icon: 'delete',
        scope: ZvTableActionScope.all,
        actionFn: (items: UserListResponseDto[]) => this.delete(items),
        isHiddenFn: () =>
          !this.auth.$context().customer?.roles.includes(ADMIN_ROLE) &&
          !this.auth.$context().customer?.roles.includes(BUSINESS_EXECUTIVE_ROLE),
      },
    ] as IZvTableAction<UserListResponseDto>[],
  });

  rolesDs = new DefaultZvSelectDataSource({
    mode: 'id',
    idKey: 'displayName',
    labelKey: 'displayName',
    items: httpGetCustomerUserRolesCanassign(this.http, {
      query: {
        target:
          this.auth.$context().customer?.roles.includes(ADMIN_ROLE) ||
          this.auth.$context().customer?.roles.includes(BUSINESS_EXECUTIVE_ROLE)
            ? AssignmentLevel.customer
            : AssignmentLevel.mandant,
      },
    }).pipe(map((roles) => roles.items)),
  });

  ngOnDestroy(): void {
    this.tableLoadTrigger$.complete();
  }

  public static getBreadcrumb(): BreadcrumbLabel {
    return $localize`:@@general.users:Benutzer`;
  }

  public changeFilter() {
    this.tableLoadTrigger$.next();
  }

  private delete(items: UserListResponseDto[]) {
    this.deleteService
      .delete({
        items: items,
        nameFn: (item) => `${item.firstName} ${item.lastName} - ${item.userName}`,
        idFn: (item) => item.userId,
        actionFn: () =>
          chunked(
            items.map((user) =>
              httpDeleteCustomerUserUserIdRemoveFromCustomer(this.http, { route: { userId: user.userId } }).pipe(
                catchError(() => of(user.userId))
              )
            )
          ).pipe(map((deleteResult) => ({ remainingObjects: (deleteResult as number[]).filter((result) => !!result) }) as DeleteResultDto)),
      })
      .afterClosed()
      .subscribe((result) => {
        if (result && result.action === DialogWrapperAction.action) {
          this.ds.updateData();
        }
      });
  }
}
