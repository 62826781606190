<zv-view [dataSource]="vds">
  <div class="app-user-list-row-details__container">
    <mat-checkbox [checked]="showInherited()" (change)="showInherited.set(!showInherited())" i18n="@@user.showInheritedRoles"
      >Vererbte Rollen anzeigen</mat-checkbox
    >
    <table class="app-user-list-row-details__table">
      @if (globalRoles()) {
        <tbody>
          <tr>
            <td colspan="2" class="app-user-list-row-details__global" i18n="@@general.global">Global</td>
            <td>{{ globalRoles() }}</td>
          </tr>
        </tbody>
      }
      <thead>
        <tr>
          <th colspan="2" i18n="@@general.level">Level</th>
          <th i18n="@@general.roles">Rollen</th>
        </tr>
      </thead>
      @for (customer of customers(); track customer.zvooveCustomerId) {
        <tbody>
          <tr>
            <td colspan="2">
              @if (customer.name) {
                {{ customer.name }}
              } @else {
                <span class="app-user-list-row-details__unknown" i18n="@@general.unknown">Unbekannt</span>
              }
            </td>
            <td>{{ customer.roles }}</td>
          </tr>
          @for (mandant of customer.mandants; track mandant.zvooveMandantId) {
            <tr>
              <th></th>
              <td>
                @if (mandant.name) {
                  {{ mandant.name }}
                } @else {
                  <span class="app-user-list-row-details__unknown" i18n="@@general.unknown">Unbekannt</span>
                }
              </td>
              <td>{{ mandant.roles }}</td>
            </tr>
          }
        </tbody>
      }
    </table>
  </div>
</zv-view>
