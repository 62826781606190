import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AuthService, ZvNavigationTabs, ZVOOVE_CUSTOMER_SUCCESS_ROLE, ZVOOVE_SUPER_ADMIN_ROLE } from '@zvoove-market/shared';

@Component({
  selector: 'app-customer-support-shell-page',
  templateUrl: './customer-support-shell.page.html',
  styleUrls: ['./customer-support-shell.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ZvNavigationTabs, RouterOutlet],
})
export class CustomerSupportShellPage {
  private auth = inject(AuthService);

  $navLinks = computed(() => {
    const navLinks = [];
    if (this.auth.$context().globalRoles.includes(ZVOOVE_CUSTOMER_SUCCESS_ROLE)) {
      navLinks.push(
        {
          label: $localize`:@@general.customers:Kunden`,
          routerLink: ['customers'],
          dataCy: 'navCustomers',
        },
        {
          label: $localize`:@@system.migrationToken:Migrationstoken`,
          routerLink: ['token'],
          dataCy: 'navMigrationToken',
        },
        {
          label: $localize`:@@general.temporaryAccesses:Temporäre Zugänge`,
          routerLink: ['temporary-access'],
          dataCy: 'navTemporaryAccess',
        },
        {
          label: $localize`:@@general.dbUploads:Datenbank Uploads`,
          routerLink: ['db-uploads'],
          dataCy: 'navDbUpload',
        }
      );
    }

    if (this.auth.$context().globalRoles.includes(ZVOOVE_SUPER_ADMIN_ROLE)) {
      navLinks.push({
        label: $localize`:@@general.users:Benutzer`,
        routerLink: ['users'],
        dataCy: 'navUsers',
      });
    }
    return navLinks;
  });
}
