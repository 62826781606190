import { HttpClient } from '@angular/common/http';
import { Injector, Signal, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import {
  AssignmentLevel,
  httpGetCustomerMandantSelectList,
  httpGetCustomerUserRolesCanassign,
  MandantSelectListMandantsDto,
} from '@zvoove-market/api';
import { addError, noDuplicateKeysValidator, removeError } from '@zvoove-market/shared';
import { DefaultZvSelectDataSource, ZvSelectLoadTrigger } from '@zvoove/components/select';
import { AutoFormArray } from '@zvoove/components/utils';
import { getAssignableRoles } from 'projects/shared/src/lib/user/role.helpers';
import { combineLatest, map, Observable, of, shareReplay, startWith, switchMap, timer } from 'rxjs';
import { MandantFormGroup } from './mandant-form-group';

export const hasRoleOnCustomerOrMandantValidator =
  (options: { message: string }): ValidatorFn =>
  (form: AbstractControl) => {
    if (!(form instanceof CustomerFormGroup)) {
      return null;
    }

    let foundRoles = form.controls.roles.value?.length ?? 0;
    form.controls.mandants.controls.forEach((control: MandantFormGroup) => {
      foundRoles += control.controls.roles.value?.length ?? 0;
    });

    if (foundRoles === 0) {
      addError(form, ['roles'], 'has_role_on_customer_or_mandant', options);
    } else {
      removeError(form, ['roles'], 'has_role_on_customer_or_mandant');
    }

    return null;
  };

export class CustomerFormGroup extends FormGroup<{
  zvooveCustomerId: FormControl<string>;
  name: FormControl<string>;
  roles: FormControl<string[]>;
  mandants: AutoFormArray<MandantFormGroup>;
}> {
  constructor(
    private injector: Injector,
    private http: HttpClient,
    private locale: string,
    private userId$: Observable<string | undefined>
  ) {
    super(
      {
        zvooveCustomerId: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
        name: new FormControl<string>('', { nonNullable: true }),
        roles: new FormControl<string[]>([], { nonNullable: true, validators: [] }),
        mandants: new AutoFormArray(() => new MandantFormGroup(this.http, this.locale, this.userId$), {
          validators: [
            noDuplicateKeysValidator('zvooveMandantId', {
              message: $localize`:@@error.mandantAlreadyAssigned:Dieser Mandant wurde bereits zugewiesen.`,
            }),
          ],
        }),
      },
      {
        validators: [
          hasRoleOnCustomerOrMandantValidator({
            message: $localize`:@@error.roleRequiredOnEitherCustomerOrMandant:Es muss mindestens eine Rolle für diesen Kunden oder einem seiner Mandanten zugewiesen werden.`,
          }),
        ],
      }
    );

    this.$selectableMandants = toSignal(this.selectableMandants$, { initialValue: [], injector: this.injector });
  }

  selectableMandants$ = timer(1)
    .pipe(
      switchMap(() =>
        combineLatest([
          this.controls.zvooveCustomerId.valueChanges.pipe(startWith(this.controls.zvooveCustomerId.value)).pipe(
            switchMap((zvooveCustomerId) => httpGetCustomerMandantSelectList(this.http, { query: { zvooveCustomerId: zvooveCustomerId } })),
            map((response) => response.items)
          ),
          this.controls.mandants.valueChanges.pipe(startWith(this.controls.mandants.value)),
        ])
      )
    )
    .pipe(
      map(([mandants, selectedMandants]) => {
        return mandants.filter(
          (mandant) => !selectedMandants.some((selectedMandant) => selectedMandant.zvooveMandantId === mandant.zvooveMandantId)
        );
      }),
      shareReplay(1)
    );
  $selectableMandants: Signal<MandantSelectListMandantsDto[]>;

  mandantDs = new DefaultZvSelectDataSource({
    mode: 'entity',
    idKey: 'zvooveMandantId',
    labelKey: 'name',
    items: this.selectableMandants$,
    loadTrigger: ZvSelectLoadTrigger.initial,
  });

  roleDs = new DefaultZvSelectDataSource({
    mode: 'id',
    idKey: 'internalName',
    labelKey: 'displayName',
    // we need a small initial delay, because the form control is not ready yet to emit values, when this class is instanciated
    items: timer(1).pipe(
      switchMap(() =>
        combineLatest([this.controls.zvooveCustomerId.valueChanges.pipe(startWith(this.controls.zvooveCustomerId.value)), this.userId$])
      ),
      switchMap(([zvooveCustomerId, userId]) => {
        let query = undefined;
        if (zvooveCustomerId) {
          if (userId) {
            query = {
              target: AssignmentLevel.customer,
              targetId: zvooveCustomerId,
              targetZvooveUserId: userId,
            };
          } else {
            query = {
              target: AssignmentLevel.customer,
              targetId: zvooveCustomerId,
            };
          }
          return httpGetCustomerUserRolesCanassign(this.http, { query: query }).pipe(
            map((response) => getAssignableRoles(response.items, this.locale, this.controls.roles.value))
          );
        }
        return of([]);
      })
    ),
    loadTrigger: ZvSelectLoadTrigger.initial,
  });

  public $mandantToAdd = signal<{ zvooveMandantId: string; name: string } | null>(null);

  public addMandant(event: MatSelectChange) {
    const mandantCtrls = this.controls.mandants;
    mandantCtrls.resizeTo(mandantCtrls.length + 1);
    mandantCtrls.controls.at(mandantCtrls.length - 1)?.patchValue({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      zvooveMandantId: event.value.zvooveMandantId,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      name: event.value.name,
    });
    setTimeout(() => this.$mandantToAdd.set(null), 0);
  }

  public removeMandant(index: number) {
    this.controls.mandants.removeAt(index);
    this.controls.mandants.markAsDirty();
  }

  public removeAllMandants() {
    this.controls.mandants.resizeTo(0);
  }
}
