import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AssignmentLevel, httpGetCustomerUserRolesCanassign } from '@zvoove-market/api';
import { DefaultZvSelectDataSource, ZvSelectLoadTrigger } from '@zvoove/components/select';
import { getAssignableRoles } from 'projects/shared/src/lib/user/role.helpers';
import { combineLatest, map, Observable, of, startWith, switchMap, timer } from 'rxjs';

export class MandantFormGroup extends FormGroup<{
  zvooveMandantId: FormControl<string>;
  name: FormControl<string>;
  roles: FormControl<string[]>;
}> {
  constructor(
    private http: HttpClient,
    private locale: string,
    private userId$: Observable<string | undefined>
  ) {
    super({
      zvooveMandantId: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      name: new FormControl<string>('', { nonNullable: true }),
      roles: new FormControl<string[]>([], { nonNullable: true, validators: [Validators.required, Validators.minLength(1)] }),
    });
  }

  roleDs = new DefaultZvSelectDataSource({
    mode: 'id',
    idKey: 'internalName',
    labelKey: 'displayName',
    // we need a small initial delay, because the form control is not ready yet to emit values, when this class is instanciated
    items: timer(1).pipe(
      switchMap(() =>
        combineLatest([this.controls.zvooveMandantId.valueChanges.pipe(startWith(this.controls.zvooveMandantId.value)), this.userId$])
      ),
      switchMap(([mandantId, userId]) => {
        if (mandantId) {
          return httpGetCustomerUserRolesCanassign(this.http, {
            query: { target: AssignmentLevel.mandant, targetId: mandantId, targetZvooveUserId: userId },
          }).pipe(map((response) => getAssignableRoles(response.items, this.locale, this.controls.roles.value)));
        }
        return of([]);
      })
    ),
    loadTrigger: ZvSelectLoadTrigger.initial,
  });
}
