import { HttpClient } from '@angular/common/http';
import { computed, inject, Injectable } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import {
  httpGetCustomerCanAccessDataPrecapture,
  httpGetCustomerIsDbUploadEnabled,
  httpGetProductGetUserHasPayroll,
} from '@zvoove-market/api';
import { ADMIN_ROLE, AuthService, BUSINESS_EXECUTIVE_ROLE } from '@zvoove-market/shared';
import { combineLatest, map, of, startWith, Subject, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CustomerNavLinkService {
  public readonly updateNavLinks$ = new Subject<void>();
  private http = inject(HttpClient);
  private auth = inject(AuthService);
  $isCustomerAdmin = computed(
    () => this.auth.$context().mandant?.roles.includes(ADMIN_ROLE) || this.auth.$context().mandant?.roles.includes(BUSINESS_EXECUTIVE_ROLE)
  );
  isCustomerAdmin$ = toObservable(this.$isCustomerAdmin);
  deploymentEnvironment$ = toObservable(computed(() => this.auth.$context().deploymentEnvironment));
  public readonly navLinks$ = this.updateNavLinks$.pipe(
    startWith(null),
    switchMap(() =>
      this.isCustomerAdmin$.pipe(
        switchMap((isCustomerAdmin) =>
          combineLatest([
            of(isCustomerAdmin),
            isCustomerAdmin ? httpGetCustomerCanAccessDataPrecapture(this.http) : of(false),
            isCustomerAdmin ? httpGetCustomerIsDbUploadEnabled(this.http) : of(false),
            isCustomerAdmin ? httpGetProductGetUserHasPayroll(this.http) : of(false),
            this.deploymentEnvironment$,
          ])
        )
      )
    ),
    map(([isCustomerAdmin, canAccessDataPrecapture, isDbEnabled, hasPayroll, deploymentEnvironment]) => {
      const ret = [];
      if (isCustomerAdmin) {
        ret.push(
          {
            label: $localize`:@@general.businessData:Unternehmensdaten`,
            routerLink: ['detail'],
            dataCy: 'navBusinessData',
          },
          { label: $localize`:@@general.contacts:Kontakte`, routerLink: ['contacts'], dataCy: 'navContacts' },
          {
            label: $localize`:@@general.customerActivityRecords:Tätigkeitsnachweise`,
            routerLink: ['activities'],
            dataCy: 'navCustomerActivityRecords',
          },
          { label: $localize`:@@general.users:Benutzer`, routerLink: ['users'], dataCy: 'navUsers' },
          {
            label: $localize`:@@general.ordersAndInvoices:Bestellungen & Rechnungen`,
            routerLink: ['invoices'],
            dataCy: 'navOrdersAndInvoices',
            children: [
              {
                label: $localize`:@@general.invoices:Rechnungen`,
                routerLink: ['invoices'],
                dataCy: 'navInvoices',
              },
              {
                label: $localize`:@@general.orders:Bestellungen`,
                routerLink: ['orders'],
                dataCy: 'navOrders',
              },
            ],
          },
          {
            label: $localize`:@@email.emails:E-Mails`,
            routerLink: ['email-accounts'],
            dataCy: 'navEmail',
            children: [
              {
                label: $localize`:@@general.accounts:Konten`,
                routerLink: ['email-accounts'],
                dataCy: 'navEmailAccounts',
              },
              {
                label: $localize`:@@email.sentMessages:Versendete Nachrichten`,
                routerLink: ['email-logs'],
                dataCy: 'navEmailSentMessages',
              },
            ],
          },
          { label: $localize`:@@customer.pdlToken:PDL Token`, routerLink: ['token'], dataCy: 'navPdlToken' },
          { label: $localize`:@@customer.advContracts:ADV-Verträge`, routerLink: ['adv-contracts'], dataCy: 'navAdvContracts' }
        );
      }

      if (isCustomerAdmin && (deploymentEnvironment === 'local' || deploymentEnvironment === 'dev')) {
        ret.push({
          label: $localize`:@@general.partnerManagement:Partner Management`,
          routerLink: ['partner-management'],
          dataCy: 'navPartnerManagement',
        });
      }

      if (canAccessDataPrecapture) {
        ret.push({
          label: $localize`:@@general.dataPreCapture:Stammdatenvorerfassung`,
          routerLink: ['dataprecapture'],
          dataCy: 'navDataPrecapture',
        });
      }
      if (isDbEnabled) {
        ret.push({
          label: $localize`:@@general.dbUpload:Datenbank Hochladen`,
          routerLink: ['db-upload'],
          dataCy: 'navDbUpload',
        });
      }
      if (hasPayroll) {
        ret.push({ label: $localize`:@@general.payrollToken:Payrolltoken`, routerLink: ['payroll-token'], dataCy: 'navPayrollToken' });
      }

      ret.push({
        label: $localize`:@@general.appointments:Termine`,
        routerLink: ['appointments'],
        dataCy: 'navAppointments',
      });
      return ret;
    })
  );
}
