@if (deviceInfo$ | async; as deviceInfo) {
  <mat-sidenav-container class="app-shell__sidenav-container" autosize>
    <mat-sidenav
      #drawer
      class="app-shell__sidenav"
      [class.app-shell__sidenav-icon-only]="!deviceInfo.isMaximized"
      [fixedInViewport]="deviceInfo.isSmall"
      [attr.role]="deviceInfo.isSmall ? 'dialog' : 'navigation'"
      [mode]="deviceInfo.isSmall ? 'over' : 'side'"
      [opened]="!deviceInfo.isSmall"
      [autoFocus]="false">
      <div class="app-shell__sidenav-items-container">
        <a class="app-shell__branding-apps" [routerLink]="['/']" routerLinkActive="router-link-active">
          @if (deviceInfo.isMaximized) {
            <img src="assets/images/zvoove_de_RGB.svg" alt="zvoove" />
          } @else {
            <img class="app-shell__minimized-signet" src="assets/images/zvoove_Signet-dark_rgb.svg" alt="zvoove" />
          }
        </a>
        <mat-nav-list dense class="app-shell__nav-list">
          @if ($hasAnyCustomerRole()) {
            <a
              class="app-shell__menu-item"
              mat-list-item
              [routerLink]="['/dashboard']"
              routerLinkActive="app-shell__router-link-active"
              i18n-matTooltip="@@general.dashboard"
              matTooltip="Dashboard"
              matTooltipPosition="right"
              [matTooltipDisabled]="deviceInfo.isMaximized"
              data-cy="dashboardSidebarButton">
              <mat-icon matListItemIcon class="app-shell__menu-item-icon">home</mat-icon>
              <span matListItemTitle class="app-shell__menu-item-text" i18n="@@general.dashboard">Dashboard</span>
            </a>
          }
          @if ($isCustomerUser()) {
            <a
              class="app-shell__menu-item"
              mat-list-item
              [routerLink]="['/customer']"
              routerLinkActive="app-shell__router-link-active"
              i18n-matTooltip="@@shell.customerArea"
              matTooltip="Kundenbereich"
              matTooltipPosition="right"
              [matTooltipDisabled]="deviceInfo.isMaximized"
              data-cy="customerAreaSidebarButton">
              <mat-icon matListItemIcon class="app-shell__menu-item-icon">business</mat-icon>
              <span matListItemTitle class="app-shell__menu-item-text" i18n="@@shell.customerArea">Kundenbereich</span>
            </a>
          }
          @if ($canSeeShop()) {
            <a
              class="app-shell__menu-item"
              mat-list-item
              [routerLink]="['/shop']"
              routerLinkActive="app-shell__router-link-active"
              i18n-matTooltip="@@shell.shop"
              matTooltip="Shop"
              matTooltipPosition="right"
              [matTooltipDisabled]="deviceInfo.isMaximized"
              data-cy="shopSidebarButton">
              <mat-icon matListItemIcon class="app-shell__menu-item-icon">store</mat-icon>
              <span matListItemTitle class="app-shell__menu-item-text" i18n="@@shell.shop">Shop</span>
            </a>
          }
          @if ($canSeeCoreBasics()) {
            <a
              class="app-shell__menu-item"
              mat-list-item
              [routerLink]="['/knowledge']"
              routerLinkActive="app-shell__router-link-active"
              i18n-matTooltip="@@shell.knowledge"
              matTooltip="Knowledge"
              matTooltipPosition="right"
              [matTooltipDisabled]="deviceInfo.isMaximized"
              data-cy="knowledgeSidebarButton">
              <mat-icon matListItemIcon class="app-shell__menu-item-icon">lightbulb</mat-icon>
              <span matListItemTitle class="app-shell__menu-item-text" i18n="@@shell.knowledge">Knowledge</span>
            </a>
            <a
              class="app-shell__menu-item"
              mat-list-item
              [routerLink]="['/downloads']"
              routerLinkActive="app-shell__router-link-active"
              i18n-matTooltip="@@general.downloads"
              matTooltip="Downloads"
              matTooltipPosition="right"
              [matTooltipDisabled]="deviceInfo.isMaximized"
              data-cy="downloadsSidebarButton">
              <mat-icon matListItemIcon class="app-shell__menu-item-icon">cloud_download</mat-icon>
              <span matListItemTitle class="app-shell__menu-item-text" i18n="@@general.downloads">Downloads</span>
            </a>
            <a
              class="app-shell__menu-item"
              mat-list-item
              [routerLink]="['/release-info']"
              routerLinkActive="app-shell__router-link-active"
              i18n-matTooltip="@@shell.releaseInfo"
              matTooltip="Release-Informationen"
              matTooltipPosition="right"
              [matTooltipDisabled]="deviceInfo.isMaximized"
              data-cy="releaseInformationSidebarButton">
              <mat-icon matListItemIcon class="app-shell__menu-item-icon">info</mat-icon>
              <span matListItemTitle class="app-shell__menu-item-text" i18n="@@shell.releaseInfo">Release-Informationen</span>
            </a>
          }
          @if ($canSeeBenchmark()) {
            <a
              class="app-shell__menu-item"
              mat-list-item
              [routerLink]="['/benchmark']"
              routerLinkActive="app-shell__router-link-active"
              data-cy="benchmarkSidebarButton">
              <mat-icon
                matListItemIcon
                class="app-shell__menu-item-icon"
                i18n-matTooltip="@@benchmark.shell"
                matTooltip="Benchmark"
                matTooltipPosition="right"
                [matTooltipDisabled]="deviceInfo.isMaximized"
                >show_chart</mat-icon
              >
              <span matListItemTitle class="app-shell__menu-item-text" i18n="@@benchmark.shell">Benchmark</span>
            </a>
          }
          @if ($userCanManageTickets()) {
            <a
              class="app-shell__menu-item"
              mat-list-item
              [routerLink]="['/tickets']"
              routerLinkActive="app-shell__router-link-active"
              i18n-matTooltip="@@general.tickets"
              matTooltip="Tickets"
              matTooltipPosition="right"
              [matTooltipDisabled]="deviceInfo.isMaximized"
              data-cy="ticketsSidebarButton">
              <mat-icon matListItemIcon class="app-shell__menu-item-icon">contact_support</mat-icon>
              <span matListItemTitle class="app-shell__menu-item-text" i18n="@@general.tickets">Tickets</span>
            </a>
          }
          @if ($isExternalSupport()) {
            <a
              class="app-shell__menu-item"
              mat-list-item
              [routerLink]="['/external-support']"
              routerLinkActive="app-shell__router-link-active"
              i18n-matTooltip="@@shell.externalSupport"
              matTooltip="Externer Support"
              matTooltipPosition="right"
              [matTooltipDisabled]="deviceInfo.isMaximized"
              data-cy="externalSupportSidebarButton">
              <mat-icon matListItemIcon class="app-shell__menu-item-icon">support_agent</mat-icon>
              <span matListItemTitle class="app-shell__menu-item-text" i18n="@@shell.externalSupport">Externer Support</span>
            </a>
          }
          @if ($isPayrollManager()) {
            <a
              class="app-shell__menu-item"
              mat-list-item
              [routerLink]="['/payroll-manager']"
              routerLinkActive="app-shell__router-link-active"
              i18n-matTooltip="@@shell.payrollManager"
              matTooltip="Payrollverwalter"
              matTooltipPosition="right"
              [matTooltipDisabled]="deviceInfo.isMaximized"
              data-cy="payrollManagerSidebarButton">
              <mat-icon matListItemIcon class="app-shell__menu-item-icon">attach_money</mat-icon>
              <span matListItemTitle class="app-shell__menu-item-text" i18n="@@shell.payrollManager">Payrollverwalter</span>
            </a>
          }
          @if ($isServiceAgent()) {
            <a
              class="app-shell__menu-item"
              mat-list-item
              [routerLink]="['/service-agent-downloads']"
              routerLinkActive="app-shell__router-link-active"
              i18n-matTooltip="@@shell.serviceAgentDownloads"
              matTooltip="Dienstleister Downloads"
              matTooltipPosition="right"
              [matTooltipDisabled]="deviceInfo.isMaximized"
              data-cy="serviceAgentSidebarButton">
              <mat-icon matListItemIcon class="app-shell__menu-item-icon">cloud_download</mat-icon>
              <span matListItemTitle class="app-shell__menu-item-text" i18n="@@shell.serviceAgentDownloads">Dienstleister Downloads</span>
            </a>
          }
          @if ($isPartner()) {
            <a
              class="app-shell__menu-item"
              mat-list-item
              [routerLink]="['/partner']"
              routerLinkActive="app-shell__router-link-active"
              i18n-matTooltip="@@general.partner"
              matTooltip="Partner"
              matTooltipPosition="right"
              [matTooltipDisabled]="deviceInfo.isMaximized"
              data-cy="partnerSidebarButton">
              <mat-icon matListItemIcon class="app-shell__menu-item-icon">assignment_ind</mat-icon>
              <span matListItemTitle class="app-shell__menu-item-text" i18n="@@general.partner">Partner</span>
            </a>
          }
          @if (this.$isZvooveEmployee()) {
            <mat-divider class="app-shell__menu-divider"></mat-divider>
          }
          @if ($isZvooveEmployee()) {
            <a
              class="app-shell__menu-item"
              mat-list-item
              [routerLink]="['/internal']"
              routerLinkActive="app-shell__router-link-active"
              i18n-matTooltip="@@general.internal"
              matTooltip="Intern"
              matTooltipPosition="right"
              [matTooltipDisabled]="deviceInfo.isMaximized"
              data-cy="internalSidebarButton">
              <mat-icon matListItemIcon class="app-shell__menu-item-icon">bar_chart</mat-icon>
              <span matListItemTitle class="app-shell__menu-item-text" i18n="@@general.internal">Intern</span>
            </a>
          }
          @if ($canSeeContentManagement()) {
            <a
              class="app-shell__menu-item"
              mat-list-item
              [routerLink]="['/content-management']"
              routerLinkActive="app-shell__router-link-active"
              i18n-matTooltip="@@general.contentManagement"
              matTooltip="Content Management"
              matTooltipPosition="right"
              [matTooltipDisabled]="deviceInfo.isMaximized"
              data-cy="contentManagementSidebarButton">
              <mat-icon matListItemIcon class="app-shell__menu-item-icon">content_paste</mat-icon>
              <span matListItemTitle class="app-shell__menu-item-text" i18n="@@general.contentManagement">Content Management</span>
            </a>
          }
          @if ($canSeeCustomerSupport()) {
            <a
              class="app-shell__menu-item"
              mat-list-item
              [routerLink]="['/customer-support']"
              routerLinkActive="app-shell__router-link-active"
              i18n-matTooltip="@@general.customerSupport"
              matTooltip="Kundenbetreuung"
              matTooltipPosition="right"
              [matTooltipDisabled]="deviceInfo.isMaximized"
              data-cy="customerSupportSidebarButton">
              <mat-icon matListItemIcon class="app-shell__menu-item-icon">help</mat-icon>
              <span matListItemTitle class="app-shell__menu-item-text" i18n="@@general.customerSupport">Kundenbetreuung</span>
            </a>
          }
          @if ($isZvooveSystemOperator()) {
            <a
              class="app-shell__menu-item"
              mat-list-item
              [routerLink]="['/system']"
              routerLinkActive="app-shell__router-link-active"
              i18n-matTooltip="@@general.system"
              matTooltip="System"
              matTooltipPosition="right"
              [matTooltipDisabled]="deviceInfo.isMaximized"
              data-cy="systemSidebarButton">
              <mat-icon matListItemIcon class="app-shell__menu-item-icon">engineering</mat-icon>
              <span matListItemTitle class="app-shell__menu-item-text" i18n="@@general.system">System</span>
            </a>
          }
        </mat-nav-list>
        @if (!deviceInfo.isSmall) {
          <mat-action-list dense class="app-shell__action-list">
            <button
              [disableRipple]="true"
              class="app-shell__menu-item width-100"
              mat-list-item
              type="button"
              (click)="toggleIconMode()"
              i18n-matTooltip="@@shell.minimize"
              matTooltip="Minimieren"
              matTooltipPosition="right"
              [matTooltipDisabled]="deviceInfo.isMaximized">
              <mat-icon matListItemIcon class="app-shell__menu-item-icon">swap_horiz</mat-icon>
              <span matListItemTitle class="app-shell__menu-item-text" data-cy="minimizeSidebarButton" i18n="@@shell.minimize"
                >Minimieren</span
              >
            </button>
          </mat-action-list>
        }
      </div>
    </mat-sidenav>
    <mat-sidenav-content class="app-shell__content">
      <mat-toolbar class="app-shell__topbar">
        @if (deviceInfo.isSmall) {
          <button mat-icon-button (click)="drawer.toggle()">
            <mat-icon>menu</mat-icon>
          </button>
        }
        <div class="app-shell__topbar-right">
          @if ($isCustomerAdmin()) {
            <a
              mat-icon-button
              data-cy="shoppingCart"
              i18n-matTooltip="@@general.shoppingCart"
              matTooltip="Warenkorb"
              [routerLink]="['./', 'shop', 'cart']">
              <mat-icon [matBadge]="shoppingCart.itemCount$ | async">shopping_cart</mat-icon>
            </a>
          }
          <button
            mat-icon-button
            [matMenuTriggerFor]="languageSubMenu"
            data-cy="changeLanguageButton"
            i18n-matTooltip="@@general.language"
            matTooltip="Sprache">
            <mat-icon>language</mat-icon>
            <mat-menu #languageSubMenu>
              @for (lang of lang.availableLanguages$ | async; track lang.code) {
                <button mat-menu-item [attr.data-cy]="lang.name + 'LanguageSelect'" (click)="changeLanguage(lang.code)">{{
                  lang.name
                }}</button>
              }
            </mat-menu>
          </button>
          @if ($isCustomerAdminOrKeyUser()) {
            <button
              mat-icon-button
              [matMenuTriggerFor]="supportSubMenu"
              data-cy="supportButton"
              i18n-matTooltip="@@general.support"
              matTooltip="Support">
              <mat-icon>headset_mic</mat-icon>
              <mat-menu #supportSubMenu>
                <a mat-menu-item [routerLink]="['temporary-support-access']" data-cy="temporarySupportAccessButton">
                  <mat-icon>meeting_room</mat-icon>
                  <span i18n="@@support.temporaryAccess">Temporäre Support-Zugänge</span>
                </a>
              </mat-menu>
            </button>
          }
          @if ($customerName()) {
            <mat-divider class="app-shell__topbar-divider app-shell__customer-info-divider" [vertical]="true"></mat-divider>
            <div class="app-shell__topbar-selected-customer-info">
              <span class="app-shell__selected-customer-info-customer-name">{{ $customerName() }}</span>
              <i class="app-shell__selected-customer-info-customer-name">{{ $mandantName() }}</i>
            </div>
          }
          <mat-divider class="app-shell__topbar-divider" [vertical]="true"></mat-divider>
          <button mat-button [matMenuTriggerFor]="accountSubMenu" data-cy="accountMenuButton" [matTooltip]="$fullName()">
            <div class="app-shell__account-button">
              {{ $fullName() }}
            </div>
            <mat-icon iconPositionEnd>account_circle</mat-icon>
            <mat-menu #accountSubMenu="matMenu" [class]="'app-hub__bigger-mat-menu'">
              <div mat-menu-item disableRipple disabled class="app-shell__account-dropdown-item app-shell__account-dropdown-userinfo">
                <mat-icon>info_outline</mat-icon>
                <div>{{ $userName() }}</div>
                <div class="app-shell__menu-selected-customer-info">
                  <span class="app-shell__selected-customer-info-customer-name">{{ $customerName() }}</span>
                  <i class="app-shell__selected-customer-info-mandant-name">{{ $mandantName() }}</i>
                </div>
                @if ($keyUserId()) {
                  <div>
                    <span i18n="@@general.keyUser">Key User</span>: <span class="app-shell__topbar-key-user-id">{{ $keyUserId() }}</span>
                  </div>
                }
              </div>
              <mat-divider></mat-divider>
              @if ($isZvooveEmployee() === false) {
                <a mat-menu-item [href]="$updateAccontUrl()" data-cy="accountSettingButton" class="app-shell__account-dropdown-item">
                  <mat-icon>manage_accounts</mat-icon>
                  <span i18n="@@shell.userProfile">Benutzerprofil</span>
                </a>
              }
              @if (!$hasNoMandantOptions()) {
                <a
                  mat-menu-item
                  [routerLink]="['/', 'mandant-select']"
                  class="app-shell__account-dropdown-item"
                  data-cy="selectMandantButton">
                  <mat-icon>people</mat-icon>
                  <span i18n="@@shell.selectMandant">Mandanten wählen</span>
                </a>
              }
              <a mat-menu-item [href]="logoutUrl" data-cy="logoutButton" class="app-shell__account-dropdown-item">
                <mat-icon>power_settings_new</mat-icon>
                <span i18n="@@shell.logout">Logout</span>
              </a>
            </mat-menu>
          </button>
        </div>
      </mat-toolbar>
      <div class="app-shell__main-content-container">
        <zv-breadcrumb [home]="homeBreadcrumb" [dataSource]="breadcrumbs"></zv-breadcrumb>
        <router-outlet></router-outlet>
      </div>
      <div class="app-shell__main-content-footer">
        <div>
          @if ($version()) {
            <span>{{ $version() }}</span>
            <span class="app-shell__main-content-footer-separator">|</span>
          }
          <span>© {{ year }} </span>
          <a href="https://zvoove.de" rel="noreferrer noopener" target="_blank">zvoove.de</a>
        </div>
        <div>
          <a [routerLink]="['/', 'imprint']" data-cy="legalNoticePageButton" i18n="@@shell.legalNotice">Impressum</a>
          <span class="app-shell__main-content-footer-separator">|</span>
          <a [routerLink]="['/', 'privacy-notice']" data-cy="privacyNoticePageButton" i18n="@@shell.privacyNotice">Datenschutz</a>
          <span class="app-shell__main-content-footer-separator">|</span>
          <a href="https://zvoove.de/agb" rel="noreferrer noopener" data-cy="termsOfUsePageButton" i18n="@@general.termsOfUse"
            >Nutzungshinweise</a
          >
          <span class="app-shell__main-content-footer-separator">|</span>
          <a [routerLink]="['/', 'licenses']" data-cy="licensesPageButton" i18n="@@general.licenses">Lizenzen</a>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
}
