import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AsyncPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, ViewChild, computed, inject } from '@angular/core';
import { MatBadge } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatAccordion } from '@angular/material/expansion';
import { MatIcon } from '@angular/material/icon';
import { MatActionList, MatListItem, MatListItemIcon, MatListItemTitle, MatNavList } from '@angular/material/list';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatSidenav, MatSidenavContainer, MatSidenavContent } from '@angular/material/sidenav';
import { MatToolbar } from '@angular/material/toolbar';
import { MatTooltip } from '@angular/material/tooltip';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import {
  $userCanManageTickets,
  ADMIN_ROLE,
  AuthService,
  BORROWER_ROLE,
  BUSINESS_EXECUTIVE_ROLE,
  BreadcrumbService,
  CUSTOMER_BASE_ACCESS_ROLE_GROUP,
  EXTERNAL_EMPLOYEE_ROLE,
  EXTERNAL_SUPPORT_ROLE,
  LanguageService,
  PARTNER_ROLE,
  PAYROLL_MANAGER_ROLE,
  SERVICE_AGENT_ROLE,
  ShoppingCartService,
  ZVOOVE_CONTENT_MANAGER_ROLE,
  ZVOOVE_CUSTOMER_SUCCESS_ROLE,
  ZVOOVE_EMPLOYEE_ROLE,
  ZVOOVE_SUPER_ADMIN_ROLE,
  ZVOOVE_SYSTEM_OPERATOR_ROLE,
  backendUrl,
  identityUrl,
} from '@zvoove-market/shared';
import { ZvBreadcrumbComponent } from 'projects/shared/src/lib/components/breadcrumb/breadcrumb.component';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  standalone: true,
  imports: [
    MatBadge,
    MatDivider,
    MatNavList,
    MatActionList,
    MatListItem,
    MatListItemIcon,
    MatListItemTitle,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
    MatSidenav,
    MatSidenavContainer,
    MatSidenavContent,
    MatToolbar,
    RouterLink,
    RouterOutlet,
    RouterLinkActive,
    AsyncPipe,
    MatTooltip,
    MatIcon,
    MatButtonModule,
    ZvBreadcrumbComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShellComponent {
  private breakpointObserver = inject(BreakpointObserver);
  private bcs = inject(BreadcrumbService);
  auth = inject(AuthService);
  private languageService = inject(LanguageService);
  shoppingCart = inject(ShoppingCartService);
  private http = inject(HttpClient);

  @ViewChild(MatAccordion)
  accordion!: MatAccordion;
  private $ctx = this.auth.$context;
  public lang = inject(LanguageService);
  public drawerIconOnly$ = new BehaviorSubject<boolean>(false);

  public $isZvooveSystemOperator = computed(() => this.$ctx().globalRoles.includes(ZVOOVE_SYSTEM_OPERATOR_ROLE));
  public $isZvooveEmployee = computed(() => this.$ctx().globalRoles.includes(ZVOOVE_EMPLOYEE_ROLE));
  public $canSeeContentManagement = computed(() =>
    this.$ctx().globalRoles.some((role) => [ZVOOVE_CONTENT_MANAGER_ROLE, ZVOOVE_SYSTEM_OPERATOR_ROLE].includes(role))
  );
  public $isCustomerAdmin = computed(
    () => this.$ctx().mandant?.roles.includes(ADMIN_ROLE) || this.$ctx().mandant?.roles.includes(BUSINESS_EXECUTIVE_ROLE)
  );
  public $hasAnyCustomerRole = computed(() => !!this.$ctx().mandant?.roles.length);
  public $isCustomerUser = computed(() => !!this.$ctx().mandant?.roles.some((role) => CUSTOMER_BASE_ACCESS_ROLE_GROUP.includes(role)));
  public $isExternalCustomer = computed(
    () => this.$ctx().mandant?.roles.includes(BORROWER_ROLE) || this.$ctx().mandant?.roles.includes(EXTERNAL_EMPLOYEE_ROLE)
  );
  public $isExternalSupport = computed(() => this.$ctx().globalRoles.includes(EXTERNAL_SUPPORT_ROLE));
  public $isPartner = computed(() => this.$ctx().globalRoles.includes(PARTNER_ROLE));
  public $canSeeCoreBasics = computed(() => (!this.$isExternalCustomer() && this.$hasAnyCustomerRole()) || this.$isZvooveEmployee());
  public $canSeeCustomerSupport = computed(() =>
    this.$ctx().globalRoles.some((role) => [ZVOOVE_CUSTOMER_SUCCESS_ROLE, ZVOOVE_SUPER_ADMIN_ROLE].includes(role))
  );
  public $isPayrollManager = computed(() => this.$ctx().globalRoles.includes(PAYROLL_MANAGER_ROLE));
  public $isServiceAgent = computed(() => this.$ctx().globalRoles.includes(SERVICE_AGENT_ROLE));
  public $userName = computed(() => this.$ctx().userName);
  public $fullName = computed(() => `${this.$ctx().firstName} ${this.$ctx().lastName}`);
  public $keyUserId = computed(() => this.$ctx().customer?.keyUserId);
  public $customerName = computed(() => this.$ctx().customer?.name);
  public $mandantName = computed(() => this.$ctx().mandant?.name);
  public $isCustomerAdminOrKeyUser = computed(() => this.$isCustomerAdmin() || !!this.$keyUserId());
  public $userCanManageTickets = $userCanManageTickets(this.auth);
  public $canSeeBenchmark = computed(
    () =>
      (this.$ctx().deploymentEnvironment === 'local' || this.$ctx().deploymentEnvironment === 'dev') && this.$ctx().customer?.hasAnalytics
  );
  public $canSeeShop = computed(() => this.$isCustomerAdmin() && !this.$ctx().customer?.isZvooveDevelopment);
  public $hasNoMandantOptions = computed(() => {
    const availableMandantsByCustomers = this.auth.$availableMandantsByCustomers();
    return availableMandantsByCustomers?.length === 1 && availableMandantsByCustomers[0].mandants.length === 1;
  });

  public deviceInfo$: Observable<DeviceInfo> = combineLatest([
    this.breakpointObserver.observe(Breakpoints.Handset),
    this.drawerIconOnly$,
  ]).pipe(
    map(([state, drawerIconOnly]) => ({
      isSmall: state.matches,
      isMaximized: !(drawerIconOnly && !state.matches),
    }))
  );

  public $version = computed(() => this.auth.$context().version);

  public logoutUrl = backendUrl() + '/bff/logout?returnUrl=/Redirect/frontend';
  public $updateAccontUrl = computed(
    () => `${identityUrl(this.auth.$context().isSsoVerify)}/ui/account/update?returnUrl=${window.location.origin}/Redirect/frontend`
  );
  public homeBreadcrumb = this.bcs.home;
  public breadcrumbs = this.bcs.dataSource;
  public year = new Date().getFullYear();

  public toggleIconMode() {
    this.drawerIconOnly$.next(!this.drawerIconOnly$.value);
  }

  public changeLanguage(languageCode: string) {
    this.languageService.switchLanguage(languageCode);
  }
}

interface DeviceInfo {
  isSmall: boolean;
  isMaximized: boolean;
}
