<div class="app-customer-support-shell-page__header">
  <h1 class="app-customer-support-shell-page__caption" data-cy="pageTitle" i18n="@@general.customerSupport">Kundenbetreuung</h1>
  <div data-cy="pageSubTitle" i18n="@@customerSupport.subTitle">Sie befinden sich im Kundenbetreuungs-Bereich.</div>
</div>

@if ($navLinks().length) {
  <zv-navigation-tabs [navLinks]="$navLinks()">
    <router-outlet></router-outlet>
  </zv-navigation-tabs>
}
