import { inject } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { externalSupportRoleGuard } from '@zvoove-market/shared';
import { ExternalSupporterShellPage } from './shell/external-supporter-shell.page';

export const externalCustomerRoutes: Routes = [
  {
    path: 'external-support',
    canActivate: [
      // Disable the route to see if feature is used at all
      () => inject(Router).parseUrl('/page-not-found'),
      externalSupportRoleGuard(),
    ],
    component: ExternalSupporterShellPage,
    data: {
      breadcrumb: () => $localize`:@@shell.externalSupport:Externer Support`,
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./external-support-ticket/external-support-ticket.module').then((m) => m.ExternalSupportTicketModule),
      },
    ],
  },
];
