/* eslint-disable @typescript-eslint/no-explicit-any */
import { PlatformLocation } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { Params, Router, UrlCreationOptions, UrlTree } from '@angular/router';

/**
 * We need to ensure, that every UrlTree that is created, has the mandantId query parameter.
 * All other methods like router.navigate, etc. act on a UrlTree, so they should work then automatically.
 */
@Injectable({ providedIn: 'root' })
export class CustomRouter extends Router {
  private platformLocation = inject(PlatformLocation);

  private getMandantIdQueryParam(): string | null {
    const search = this.platformLocation?.search ?? '';
    return new URLSearchParams(search).get('mandantId');
  }

  override parseUrl(url: string): UrlTree {
    const urlTree = super.parseUrl(url);
    this.addMandantIdQueryParam(urlTree);
    return urlTree;
  }

  override createUrlTree(commands: any[], navigationExtras?: UrlCreationOptions): UrlTree {
    navigationExtras ??= {};
    this.addMandantIdQueryParam(navigationExtras);
    return super.createUrlTree(commands, navigationExtras);
  }

  private addMandantIdQueryParam<T extends { queryParams?: Params | null }>(extras: T) {
    if (!extras.queryParams?.mandantId) {
      const mandantId = this.getMandantIdQueryParam();
      if (mandantId) {
        extras.queryParams = {
          mandantId: mandantId,
          ...extras.queryParams,
        };
      }
    }
  }
}
