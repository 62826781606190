import { Routes } from '@angular/router';
import {
  BORROWER_ROLE,
  CUSTOMER_BASE_ACCESS_ROLE_GROUP,
  EXTERNAL_EMPLOYEE_ROLE,
  firstAdminLoginGuard,
  roleGuard,
} from '@zvoove-market/shared';
import { DashboardShellPage } from './shell/dashboard-shell.page';

export const dashboardRoutes: Routes = [
  {
    path: 'dashboard',
    canActivate: [
      firstAdminLoginGuard(),
      roleGuard({ mandantRoles: [...CUSTOMER_BASE_ACCESS_ROLE_GROUP, BORROWER_ROLE, EXTERNAL_EMPLOYEE_ROLE], condition: 'any' }),
    ],
    component: DashboardShellPage,
    data: {
      breadcrumb: () => $localize`:@@general.dashboard:Dashboard`,
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'news',
        data: {
          breadcrumb: () => $localize`:@@general.news:Neuigkeiten`,
        },
        loadChildren: () => import('./news/news.module').then((m) => m.NewsModule),
      },
    ],
  },
];
