<zv-view [dataSource]="ds">
  <zv-card>
    @if (item) {
      <zv-header [caption]="item.title"></zv-header>
      <div class="app-service-agent-download-detail-page__table">
        <div><span i18n="@@general.date">Datum</span><span>:</span></div>
        <div>{{ item.dateValidFrom | date: 'shortDate' }}</div>
        <div><span i18n="@@general.version">Version</span><span>:</span></div>
        <div>{{ item.version }}</div>
        <div><span i18n="@@general.category">Kategorie</span><span>:</span></div>
        <div>{{ item.categoryName }}</div>
        <div><span i18n="@@general.products">Produkte</span><span>:</span></div>
        <div>{{ item.productsString }}</div>
        <div><span i18n="@@general.filename">Dateiname</span><span>:</span></div>
        <div>{{ item.fileName }}</div>
        <div><span i18n="@@general.size">Größe</span><span>:</span></div>
        <div>{{ item.fileSize | formatFileSize }}</div>
        <div><span i18n="@@general.description">Beschreibung</span><span>:</span></div>
        <div [innerHTML]="item.description"></div>
      </div>
      <a
        [href]="downloadUrl$ | async"
        target="_blank"
        rel="noopener noreferrer"
        mat-flat-button
        color="primary"
        data-cy="downloadButton"
        i18n="@@general.download"
        >Download</a
      >
    }
  </zv-card>
</zv-view>
