import { Routes } from '@angular/router';
import { payrollManagerRoleGuard } from '@zvoove-market/shared';
import { PayrollManagerShellPage } from './shell/payroll-manager-shell.page';

export const payrollManagerRoutes: Routes = [
  {
    path: 'payroll-manager',
    canActivate: [payrollManagerRoleGuard()],
    component: PayrollManagerShellPage,
    data: {
      breadcrumb: () => $localize`:@@shell.payrollManager:Payrollverwalter`,
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./payroll-manager-ticket/payroll-manager-ticket.module').then((m) => m.PayrollManagerTicketModule),
      },
    ],
  },
];
