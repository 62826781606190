import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatLabel } from '@angular/material/input';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import {
  httpGetCustomerUserAdministrationAllRoles,
  httpGetCustomerUserAdministrationList,
  httpPostCustomerUserUserIdResendInviteCode,
  httpPostUserDelete,
  httpPostUserForgotPassword,
  UserAdministrationListUserResponseDto,
} from '@zvoove-market/api';
import { ConfirmDialog, ConfirmDialogDataIn, DeleteService, DialogWrapperAction, DialogWrapperDataOut } from '@zvoove-market/shared';
import { ZvFormField } from '@zvoove/components/form-field';
import { DefaultZvSelectDataSource, ZvSelect } from '@zvoove/components/select';
import { ZvTableActionScope, ZvTableDataSource, ZvTableModule } from '@zvoove/components/table';
import { map, tap } from 'rxjs';
import { UserListRowDetailsComponent } from './user-list-row-details.component';

@Component({
  selector: 'app-user-list-page',
  templateUrl: './user-list.page.html',
  styleUrls: ['./user-list.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatLabel,
    ZvFormField,
    UserListRowDetailsComponent,
    MatIcon,
    RouterLink,
    ZvSelect,
    ZvTableModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatTooltip,
  ],
})
export class UserListPage {
  http = inject(HttpClient);
  deleteService = inject(DeleteService);
  dialog = inject(MatDialog);
  router = inject(Router);
  route = inject(ActivatedRoute);

  filterForm = new FormGroup({
    roles: new FormControl<string[]>([], { nonNullable: true }),
  });

  // Type needs to be set explicitly because one of the actions references this.ds
  public ds: ZvTableDataSource<
    {
      zvooveUserId: string;
      email: string;
      firstName: string | null;
      lastName: string | null;
      registrationStatus: string;
      associatedCustomersCount: number;
      hasGlobalRoles: boolean;
    },
    string[]
  > = new ZvTableDataSource<
    {
      zvooveUserId: string;
      email: string;
      firstName: string | null;
      lastName: string | null;
      registrationStatus: string;
      associatedCustomersCount: number;
      hasGlobalRoles: boolean;
    },
    string[]
  >({
    mode: 'server',
    loadTrigger$: this.route.queryParamMap.pipe(
      map((params) => {
        const rolesStr = params.get('roles');
        if (!rolesStr) return [];
        return rolesStr.split(',');
      })
    ),
    loadDataFn: (param) => {
      this.filterForm.patchValue({ roles: param.triggerData }, { emitEvent: false });
      return httpGetCustomerUserAdministrationList(this.http, {
        query: {
          searchStr: param.searchText ?? '',
          skip: param.currentPage * param.pageSize,
          take: param.pageSize,
          sortColumn: param.sortColumn ?? 'Email',
          sortOrder: param.sortDirection ?? 'asc',
          roleInternalNames: param.triggerData,
        },
      }).pipe(
        map((result) => ({
          ...result,
          items: result.items.map((user) => ({
            ...user,
          })),
        }))
      );
    },
    actions: [
      {
        label: $localize`:@@general.resetPassword:Passwort zurücksetzen`,
        icon: 'lock_reset',
        scope: ZvTableActionScope.row,
        actionFn: (items) =>
          this.dialog.open<ConfirmDialog, ConfirmDialogDataIn, DialogWrapperDataOut<void>>(ConfirmDialog, {
            data: {
              title: $localize`:@@general.resetPassword:Passwort zurücksetzen`,
              content: $localize`:@@general.confirmResetPassword:Wollen Sie wirklich das Passwort von ${items[0].email} zurücksetzen?`,
              actionFn: () => httpPostUserForgotPassword(this.http, { query: { zvooveUserId: items[0].zvooveUserId ?? '' } }),
            },
          }),
      },
      {
        label: $localize`:@@general.resendMail:E-Mail erneut senden`,
        icon: 'email',
        scope: ZvTableActionScope.row,
        actionFn: (items) =>
          httpPostCustomerUserUserIdResendInviteCode(this.http, { route: { userId: items[0].zvooveUserId } })
            .pipe(tap(() => this.ds.updateData()))
            .subscribe(),
        isHiddenFn: (items) => items[0].registrationStatus !== 'InvitationExpired',
      },
      {
        label: $localize`:@@general.delete:Löschen`,
        icon: 'delete',
        scope: ZvTableActionScope.all,
        actionFn: (items) => this.delete(items),
      },
    ],
  });

  rolesDs = new DefaultZvSelectDataSource({
    mode: 'id',
    idKey: 'internalName',
    labelKey: 'displayName',
    items: httpGetCustomerUserAdministrationAllRoles(this.http, { query: {} }).pipe(map((dto) => dto.items)),
  });

  public changeFilter() {
    this.router.navigate(['./'], {
      relativeTo: this.route,
      queryParams: {
        roles: this.filterForm.value.roles?.join(',') ?? '',
      },
    });
  }

  private delete(items: UserAdministrationListUserResponseDto[]) {
    this.deleteService
      .delete({
        items: items,
        nameFn: (item) => `${item.firstName} ${item.lastName} - ${item.email}`,
        idFn: (item) => item.email,
        actionFn: () => httpPostUserDelete(this.http, { body: items.map((user) => user.zvooveUserId) }),
      })
      .afterClosed()
      .subscribe((result) => {
        if (result && result.action === DialogWrapperAction.action) {
          this.ds.updateData();
        }
      });
  }
}
