import { Routes } from '@angular/router';
import { SERVICE_AGENT_ROLE, roleGuard } from '@zvoove-market/shared';
import { ServiceAgentDownloadsDetailPage } from './service-agent-downloads/detail/service-agent-downloads-detail.page';

export const serviceAgentRoutes: Routes = [
  {
    path: 'service-agent-downloads',
    canActivate: [roleGuard({ globalRoles: [SERVICE_AGENT_ROLE], condition: 'any' })],
    loadComponent: () => import('./shell/service-agent-shell.page').then((m) => m.ServiceAgentShellPage),
    data: {
      breadcrumb: () => $localize`:@@shell.serviceAgentDownloads:Dienstleister Downloads`,
    },
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./service-agent-downloads/list/service-agent-downloads-list.page').then((m) => m.ServiceAgentDownloadsListPage),
      },
      {
        path: 'download/:downloadId',
        loadComponent: () =>
          import('./service-agent-downloads/detail/service-agent-downloads-detail.page').then((m) => m.ServiceAgentDownloadsDetailPage),
        data: {
          breadcrumb: ServiceAgentDownloadsDetailPage,
        },
      },
    ],
  },
];
