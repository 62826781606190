import { AsyncPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { httpGetProductGetProductGroupShorthandAndNameForOwnedProducts } from '@zvoove-market/api';
import { IZvNavigationTabItem, ZvNavigationTabs } from '@zvoove-market/shared';
import { Observable, combineLatest, distinctUntilChanged, filter, map, startWith } from 'rxjs';

@Component({
  selector: 'app-release-info-shell-page',
  templateUrl: './release-info-shell.page.html',
  styleUrls: ['./release-info-shell.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ZvNavigationTabs, RouterOutlet, AsyncPipe],
})
export class ReleaseInfoShellPage {
  private http = inject(HttpClient);
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  productShorthand$ = this.router.events.pipe(
    filter((e) => e instanceof NavigationEnd),
    startWith(null),
    map(() => this.route.snapshot.firstChild?.paramMap.get('productShorthand')),
    distinctUntilChanged()
  );

  navLinks$: Observable<IZvNavigationTabItem[]> = combineLatest([
    httpGetProductGetProductGroupShorthandAndNameForOwnedProducts(this.http),
    this.productShorthand$,
  ]).pipe(
    map(([products, productShortHand]) => {
      const navLinks = products.map((product) => ({
        label: product.name,
        routerLink: [product.shorthand],
        dataCy: `navProduct-${product.shorthand}`,
      }));

      if (navLinks.length > 0 && !productShortHand) {
        const navLink = navLinks[0];
        this.router.navigate(navLink.routerLink, { relativeTo: this.route, replaceUrl: true });
      }

      return navLinks;
    })
  );
}
