<zv-form [dataSource]="ds" [formGroup]="ds.form">
  <zv-card i18n-caption="@@customer.inviteUser" caption="Benutzer einladen">
    <div class="app-customer-user-detail-page__grid">
      <zv-form-field class="app-customer-user-detail-page__entire-row">
        <input matInput formControlName="userName" type="email" data-cy="emailInput" />
        <mat-label i18n="@@customerUser.userNameEmail">Benutzername (E-Mail)</mat-label>
      </zv-form-field>
      <zv-form-field>
        <input matInput formControlName="firstName" type="text" data-cy="firstNameInput" />
        <mat-label i18n="@@general.firstName">Vorname</mat-label>
      </zv-form-field>
      <zv-form-field>
        <input matInput formControlName="lastName" type="text" data-cy="lastNameInput" />
        <mat-label i18n="@@general.lastName">Nachname</mat-label>
      </zv-form-field>
      @if (isCustomerAdmin) {
        <zv-form-field>
          <zv-select [dataSource]="roleSelectForCustomerDs" [multiple]="true" formControlName="customerRoles" data-cy="customerRolesSelect">
            <ng-container *zvSelectOptionTemplate="let item">
              <span [style.color]="item.entity?.disabled ? 'var(--zv-error)' : null">{{ item.label }}</span>
            </ng-container>
          </zv-select>
          <mat-label i18n="@@general.rolesOnCustomerLevel">Unternehmensweite Rollen</mat-label>
        </zv-form-field>
      } @else if (customerRoles.length) {
        <div class="app-customer-user-detail-page__overarching-roles-info">
          <mat-icon>info</mat-icon>
          <span i18n="@@customer.customerLevelRolesInfo">
            Diesem Benutzer sind folgende Rollen unternehmensweit zugewiesen: {{ customerRoles.join(', ') }}
          </span>
        </div>
      }
    </div>
  </zv-card>

  <zv-card
    i18n-caption="@@user.AssignedMandants"
    caption="Zugewiesene Mandanten"
    i18n-description="@@user.AssignedMandantsDescription"
    description="Hier können Sie Rollen direkt auf Mandantebene zuweisen, die unternehmensglobalen Rollen von oben werden auf alle Mandanten vererbt."
    class="app-customer-user-detail-page__mandant-card">
    <ng-container *zvCardTopButtonSection>
      <button color="primary" mat-mini-fab (click)="addMandantToList()" data-cy="addMandantToList">
        <mat-icon>add</mat-icon>
      </button>
    </ng-container>

    @if (mandants.length) {
      <ng-container formArrayName="assignedMandants">
        @for (mandant of mandants; track mandant; let i = $index) {
          <div [formGroupName]="i" class="app-customer-user-detail-page__mandant-wrapper">
            <zv-form-field>
              <zv-select [dataSource]="mandant.mandantDs" formControlName="mandantId" data-cy="mandantSelect"></zv-select>
              <mat-label i18n="@@general.mandant">Mandant</mat-label>
            </zv-form-field>
            <zv-form-field>
              <zv-select [dataSource]="mandant.roleDs" [multiple]="true" formControlName="mandantRoles" data-cy="mandantRolesSelect">
                <ng-container *zvSelectOptionTemplate="let item">
                  <span [style.color]="item.entity?.disabled ? 'var(--zv-error)' : null">{{ item.label }}</span>
                </ng-container>
              </zv-select>
              <mat-label i18n="@@general.roles">Rollen</mat-label>
            </zv-form-field>
            <button
              mat-icon-button
              class="app-customer-user-detail-page__customer-remove"
              (click)="removeMandant(i)"
              data-cy="deleteAssociatedMandantButton">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        }
      </ng-container>
    } @else {
      <span class="text-bold" data-cy="noMandantRolesText" i18n="@@user.NoRolesOnMandantLevel"
        >Bisher wurden keine Rollen auf Mandantebene vergeben.</span
      >
    }
  </zv-card>
</zv-form>
