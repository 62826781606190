<div class="app-customer-shell-page__header">
  <h1 class="app-customer-shell-page__caption" data-cy="pageTitle" i18n="@@general.knowledge">Knowledge</h1>
  <div data-cy="pageSubTitle" i18n="@@knowledge.shellDescriptions">Hier finden Sie viele hilfreiche Informationen aus der zvoove Welt.</div>
</div>

@if (navLinks$ | async; as navLinks) {
  <zv-navigation-tabs [navLinks]="navLinks">
    <router-outlet></router-outlet>
  </zv-navigation-tabs>
}
