import {
  AssignmentLevel,
  UserAdministrationDetailsResponseDto,
  UserAdministrationInviteRequestDto,
  UserAdministrationUpdateRequestDto,
} from '@zvoove-market/api';

export interface UserDetailFormData {
  email: string;
  firstName: string;
  lastName: string;
  globalRoles: string[];
  customers: { zvooveCustomerId: string; roles: string[]; mandants: { zvooveMandantId: string; roles: string[] }[] }[];
}

export interface UserDetailBeforeEdit {
  globalRoles: UserAdministrationDetailsResponseDto['globalRoles'];
  assignedCustomers: UserAdministrationDetailsResponseDto['assignedCustomers'];
}

export function createSaveObject(
  formData: UserDetailFormData,
  userToEdit: UserDetailBeforeEdit | null
): UserAdministrationUpdateRequestDto & UserAdministrationInviteRequestDto {
  if (!userToEdit) {
    userToEdit = {
      globalRoles: [],
      assignedCustomers: [],
    };
  }

  const dto: UserAdministrationUpdateRequestDto & UserAdministrationInviteRequestDto = {
    email: formData.email,
    firstName: formData.firstName,
    lastName: formData.lastName,
    rolesToAdd: calculateRolesToAdd(formData, userToEdit),
    rolesToRemove: calculateRolesToRemove(formData, userToEdit),
  };
  return dto;
}

function calculateRolesToAdd(formData: UserDetailFormData, userToEdit: UserDetailBeforeEdit) {
  const rolesToAdd = [];
  for (const role of formData.globalRoles) {
    if (!userToEdit.globalRoles.some((r) => r.internalName === role)) {
      rolesToAdd.push({ internalName: role, target: AssignmentLevel.global, targetId: null });
    }
  }

  for (const formDataCustomer of formData.customers) {
    const oldCustomer = userToEdit.assignedCustomers.find((c) => c.zvooveCustomerId === formDataCustomer.zvooveCustomerId);
    const oldCustomerRoles = oldCustomer?.roles.filter((r) => !r.isInherited).map((r) => r.internalName) ?? [];
    for (const formDataRole of formDataCustomer.roles) {
      if (!oldCustomerRoles.includes(formDataRole)) {
        rolesToAdd.push({ internalName: formDataRole, target: AssignmentLevel.customer, targetId: formDataCustomer.zvooveCustomerId });
      }
    }

    for (const formDataMandant of formDataCustomer.mandants) {
      const oldMandantRoles =
        oldCustomer?.assignedMandants
          .find((m) => m.zvooveMandantId === formDataMandant.zvooveMandantId)
          ?.roles.filter((r) => !r.isInherited)
          .map((r) => r.internalName) ?? [];
      for (const formDataRole of formDataMandant.roles) {
        if (!oldMandantRoles.includes(formDataRole)) {
          rolesToAdd.push({ internalName: formDataRole, target: AssignmentLevel.mandant, targetId: formDataMandant.zvooveMandantId });
        }
      }
    }
  }

  return rolesToAdd;
}

function calculateRolesToRemove(formData: UserDetailFormData, userToEdit: UserDetailBeforeEdit) {
  const rolesToRemove = [];
  for (const role of userToEdit.globalRoles) {
    if (!formData.globalRoles.includes(role.internalName)) {
      rolesToRemove.push({ internalName: role.internalName, target: AssignmentLevel.global, targetId: null });
    }
  }

  for (const oldCustomer of userToEdit.assignedCustomers) {
    const formDataCustomer = formData.customers.find((c) => c.zvooveCustomerId === oldCustomer.zvooveCustomerId);
    const formDataCustomerRoles = formDataCustomer?.roles ?? [];
    for (const oldCustomerRole of oldCustomer.roles.filter((r) => !r.isInherited).map((r) => r.internalName)) {
      if (!formDataCustomerRoles.includes(oldCustomerRole)) {
        rolesToRemove.push({
          internalName: oldCustomerRole,
          target: AssignmentLevel.customer,
          targetId: oldCustomer.zvooveCustomerId,
        });
      }
    }

    for (const oldMandant of oldCustomer.assignedMandants) {
      const formDataMandantRoles = formDataCustomer?.mandants.find((m) => m.zvooveMandantId === oldMandant.zvooveMandantId)?.roles ?? [];
      for (const oldMandantRole of oldMandant.roles.filter((r) => !r.isInherited).map((r) => r.internalName)) {
        if (!formDataMandantRoles.includes(oldMandantRole)) {
          rolesToRemove.push({
            internalName: oldMandantRole,
            target: AssignmentLevel.mandant,
            targetId: oldMandant.zvooveMandantId,
          });
        }
      }
    }
  }
  return rolesToRemove;
}
