import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { IZvNavigationTabItem, ZvNavigationTabs } from '@zvoove-market/shared';

@Component({
  selector: 'app-system-shell-page',
  templateUrl: './system-shell.page.html',
  styleUrls: ['./system-shell.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ZvNavigationTabs, RouterOutlet],
})
export class SystemShellPage {
  navLinks: IZvNavigationTabItem[] = [
    { label: $localize`:@@general.actions:Aktionen`, routerLink: ['actions'], dataCy: 'navActions' },
    { label: $localize`:@@general.token:Token`, routerLink: ['customer-token'], dataCy: 'navCustomerToken' },
    { label: $localize`:@@general.demoData:Demo-Daten`, routerLink: ['demo-data'], dataCy: 'demoData' },
  ];
}
