/// <reference types="@angular/localize" />

import { ApplicationConfig, enableProdMode, ErrorHandler, provideZoneChangeDetection } from '@angular/core';
import {
  authGuard,
  backendUrl,
  bootstrapAngular,
  FormService,
  GlobalErrorHandler,
  HubHttpInterceptor,
  mandantSelectedGuard,
  statusCodeInterceptor,
  TableSettingsService,
  versionCheckInterceptor,
  ZvExceptionMessageExtractorExtension,
} from '@zvoove-market/shared';

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, Router, withRouterConfig } from '@angular/router';
import { benchmarkRoutes } from '@zvoove-market/ui-benchmark';
import { contentManagementRoutes } from '@zvoove-market/ui-content-management';
import { customerRoutes } from '@zvoove-market/ui-customer';
import { customerSupportRoutes } from '@zvoove-market/ui-customer-support';
import { dashboardRoutes } from '@zvoove-market/ui-dashboard';
import { downloadRoutes } from '@zvoove-market/ui-download';
import { externalCustomerRoutes } from '@zvoove-market/ui-external-supporter';
import { generalRoutes } from '@zvoove-market/ui-general';
import { knowledgeRoutes } from '@zvoove-market/ui-knowledge';
import { partnerRoutes } from '@zvoove-market/ui-partner';
import { payrollManagerRoutes } from '@zvoove-market/ui-payroll-manager';
import { releaseInfoRoutes } from '@zvoove-market/ui-release-info';
import { serviceAgentRoutes } from '@zvoove-market/ui-service-agent';
import { shopRoutes } from '@zvoove-market/ui-shop';
import { systemRoutes } from '@zvoove-market/ui-system';
import { ticketRoutes } from '@zvoove-market/ui-ticket';
import { zvooveEmployeeRoutes } from '@zvoove-market/ui-zvoove-employee';
import {
  provideDateTimeAdapters,
  provideDateTimeFormats,
  ZV_NATIVE_DATE_FORMATS,
  ZV_NATIVE_TIME_FORMATS,
  ZvExceptionMessageExtractor,
  ZvNativeDateAdapter,
  ZvNativeDateTimeAdapter,
  ZvNativeTimeAdapter,
} from '@zvoove/components/core';
import { ZvFormService } from '@zvoove/components/form-base';
import { DefaultZvSelectService, ZvSelectService } from '@zvoove/components/select';
import { ZvTableSettingsService } from '@zvoove/components/table';
import { from } from 'rxjs';
import { i18nModule } from 'src/app/i18n/i18n.module';
import { AppComponent } from './app/app.component';
import { CustomRouter } from './app/custom-router';
import { ShellComponent } from './app/shell/shell.component';
import { environment } from './environments/environment';

const startPage = 'dashboard';

if (environment.production) {
  enableProdMode();
}

bootstrapAngular({
  configUrl: backendUrl(),
  prod: environment.production,
  bootstrapFunc: () => bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err)),
});

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    { provide: Router, useClass: CustomRouter },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    provideHttpClient(
      withInterceptors([
        versionCheckInterceptor({
          appVersion: document.querySelector('meta[name="version"]')?.getAttribute('content') ?? '',
          getNewestAppVersion: () =>
            from(
              fetch('/index.html')
                .then((res) => res.text())
                .then((html) => {
                  const parser = new DOMParser();
                  const htmlDoc = parser.parseFromString(html, 'text/html');
                  return htmlDoc.querySelector('meta[name="version"]')?.getAttribute('content') ?? '';
                })
            ),
          throttleMs: 60000,
        }),
        statusCodeInterceptor({ statusCodesToLog: [403] }),
      ]),
      withInterceptorsFromDi()
    ),
    { provide: HTTP_INTERCEPTORS, useClass: HubHttpInterceptor, multi: true },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } },
    i18nModule.setLocale(),
    i18nModule.setLocaleId(),
    { provide: ZvFormService, useClass: FormService },
    { provide: ZvSelectService, useClass: DefaultZvSelectService },
    { provide: ZvExceptionMessageExtractor, useClass: ZvExceptionMessageExtractorExtension },
    { provide: ZvTableSettingsService, useClass: TableSettingsService },
    provideDateTimeAdapters(ZvNativeDateTimeAdapter, ZvNativeDateAdapter, ZvNativeTimeAdapter),
    provideDateTimeFormats(ZV_NATIVE_DATE_FORMATS, ZV_NATIVE_TIME_FORMATS),
    provideAnimations(),
    provideRouter(
      [
        {
          path: '',
          redirectTo: startPage,
          pathMatch: 'full',
        },
        {
          path: 'Redirect/frontend',
          redirectTo: startPage,
          pathMatch: 'full',
        },
        {
          path: '',
          canActivate: [authGuard()],
          component: ShellComponent,
          children: [
            {
              path: '',
              canActivate: [mandantSelectedGuard()],
              runGuardsAndResolvers: 'always',
              children: [...dashboardRoutes, ...customerRoutes, ...shopRoutes, ...ticketRoutes, ...benchmarkRoutes],
            },
            {
              path: '',
              canActivate: [mandantSelectedGuard({ allowBypassForZvoove: true })],
              runGuardsAndResolvers: 'always',
              children: [...knowledgeRoutes, ...downloadRoutes, ...releaseInfoRoutes],
            },
            // Routes that don't require a customer to be selected
            ...serviceAgentRoutes,
            ...externalCustomerRoutes,
            ...partnerRoutes,
            ...payrollManagerRoutes,
            ...zvooveEmployeeRoutes,
            ...contentManagementRoutes,
            ...customerSupportRoutes,
            ...systemRoutes,
            // Must always be last and directly on path '' so that the page-not-found route is the very last one on top level
            ...generalRoutes,
          ],
        },
      ],
      withRouterConfig({ paramsInheritanceStrategy: 'always' })
    ),
  ],
};
