import { Routes } from '@angular/router';
import { zvooveSystemOperatorRoleGuard } from '@zvoove-market/shared';
import { SystemShellPage } from './shell/system-shell.page';

export const systemRoutes: Routes = [
  {
    path: 'system',
    canActivate: [zvooveSystemOperatorRoleGuard()],
    component: SystemShellPage,
    data: {
      breadcrumb: () => $localize`:@@general.system:System`,
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'actions',
      },
      {
        path: 'actions',
        data: {
          breadcrumb: () => $localize`:@@general.actions:Aktionen`,
        },
        loadComponent: () => import('./actions/actions.page').then((m) => m.ActionsPage),
      },
      {
        path: 'customer-token',
        data: {
          breadcrumb: () => $localize`:@@general.token:Token`,
        },
        loadChildren: () => import('./customer-token/customer-token.routes').then((m) => m.CUSTOMER_TOKEN_ROUTES),
      },
      {
        path: 'demo-data',
        loadComponent: () => import('./demo-data/demo-data.page').then((m) => m.DemoDataPage),
      },
    ],
  },
];
