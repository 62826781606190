import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { RouterOutlet } from '@angular/router';
import { AuthService, IZvNavigationTabItem, ZvNavigationTabs, ZVOOVE_CONTENT_MANAGER_ROLE } from '@zvoove-market/shared';

@Component({
  selector: 'app-content-management-shell-page',
  templateUrl: './content-management-shell.page.html',
  styleUrls: ['./content-management-shell.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIcon, RouterOutlet, ZvNavigationTabs],
})
export class ContentManagementShellPage {
  private auth = inject(AuthService);
  public canEdit = computed(() => this.auth.$context().globalRoles.includes(ZVOOVE_CONTENT_MANAGER_ROLE));

  navLinks: IZvNavigationTabItem[] = [
    {
      label: $localize`:@@general.downloads:Downloads`,
      routerLink: ['downloads'],
      dataCy: 'navDownloads',
    },
    {
      label: $localize`:@@general.videos:Videos`,
      routerLink: ['videos'],
      dataCy: 'navVideos',
    },
    {
      label: $localize`:@@knowledge.faq:Fragen & Antworten`,
      routerLink: ['faq-entries'],
      dataCy: 'navFaqEntries',
      children: [
        { label: $localize`:@@general.faqEntries:FAQ Einträge`, routerLink: ['faq-entries'] },
        { label: $localize`:@@general.faqCategories:FAQ Kategorien`, routerLink: ['faq-categories'], dataCy: 'navFaqCategories' },
        { label: $localize`:@@general.faqStatistics:FAQ Statistiken`, routerLink: ['faq-statistics'], dataCy: 'navFaqStatistics' },
      ],
    },
    {
      label: $localize`:@@general.dashboardTiles:Dashboard Kacheln`,
      routerLink: ['dashboard-tiles'],
      dataCy: 'navDashboardTiles',
    },
    {
      label: $localize`:@@knowledge.serviceCatalog:Dienstleistungskatalog`,
      routerLink: ['service-catalogs'],
      dataCy: 'navServiceCatalogs',
    },
    {
      label: $localize`:@@shell.releaseInfo:Release-Informationen`,
      routerLink: ['release-info'],
      dataCy: 'navReleaseInfo',
    },
    {
      label: $localize`:@@general.email:E-Mail`,
      routerLink: ['email-templates'],
      dataCy: 'navEmail',
      children: [
        { label: $localize`:@@general.templates:Vorlagen`, routerLink: ['email-templates'] },
        { label: $localize`:@@general.bulkEmails:Serienmails`, routerLink: ['bulk'], dataCy: 'navBulkEmails' },
      ],
    },
  ];
}
