import { coerceNumberProperty } from '@angular/cdk/coercion';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnDestroy, computed, inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInput, MatLabel, MatSuffix } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DashboardTileCategory,
  DashboardTileDetailDto,
  DashboardTileInternationalizationContent,
  Priority,
  httpGetDashboardTileGetDetail,
  httpGetProductGetProductPicklist,
  httpPostDashboardTileCreate,
  httpPostDashboardTileUpdate,
} from '@zvoove-market/api';
import {
  AuthService,
  BreadcrumbLabel,
  FormDataSource,
  LanguageService,
  ZVOOVE_CONTENT_MANAGER_ROLE,
  acceptableFileExtensionsValidator,
  convertFilesize,
  dashboardTileCategoryItems,
  maxFileSizeValidator,
  requiredIfValidator,
  watchProgressEmitBody,
} from '@zvoove-market/shared';
import { ZvCard } from '@zvoove/components/card';
import { ZvDateTimeInput } from '@zvoove/components/date-time-input';
import { ZvFileInput } from '@zvoove/components/file-input';
import { ZvForm } from '@zvoove/components/form';
import { ZvFormField } from '@zvoove/components/form-field';
import { DefaultZvSelectDataSource, ZvSelect } from '@zvoove/components/select';
import { AutoFormArray } from '@zvoove/components/utils';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

interface ExtendedDashboardTileI18NContent extends DashboardTileInternationalizationContent {
  languageName: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatLabel,
    ZvFormField,
    ZvForm,
    MatTabsModule,
    MatDatepickerModule,
    ZvDateTimeInput,
    ZvFileInput,
    ZvSelect,
    ZvCard,
    MatCheckbox,
    ReactiveFormsModule,
    MatInput,
    MatSuffix,
    MatButtonModule,
  ],
  selector: 'app-dashboard-tile-detail-page',
  standalone: true,
  styleUrls: ['./dashboard-tile-detail.page.scss'],
  templateUrl: './dashboard-tile-detail.page.html',
})
export class DashboardTileDetailPage implements OnDestroy {
  private static breadcrumbData$ = new BehaviorSubject<string | null>(null);
  private languageService = inject(LanguageService);
  private http = inject(HttpClient);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private auth = inject(AuthService);
  canEdit = computed(() => this.auth.$context().globalRoles.includes(ZVOOVE_CONTENT_MANAGER_ROLE));
  private tileId?: number | null;
  private maxFileSize = 6 * 1024 * 1024;
  private initialUpdateFile: File | null = null;
  acceptedFileTypes = ['.tif', '.tiff', '.bmp', '.jpg', '.jpeg', '.gif', '.png', '.webp'];
  imageHint = $localize`:@@general.fileUploadSizeFormatHint:Sie können eine Datei von bis zu ${convertFilesize(
    this.maxFileSize,
    'B'
  )} in einem der folgenden Formate hochladen: ${this.acceptedFileTypes.join(', ')}`;

  formGroup = new FormGroup(
    {
      internalName: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(75)],
      }),
      requiredProductShorthands: new FormControl<string[]>([], {
        nonNullable: true,
        validators: [Validators.required, Validators.minLength(1)],
      }),
      dashboardTileCategory: new FormControl(DashboardTileCategory.news),
      validFromDate: new FormControl<Date>(new Date(), Validators.required),
      expireDate: new FormControl<Date | null>(null),
      priority: new FormControl(Priority.medium, { nonNullable: true, validators: Validators.required }),
      isWarning: new FormControl(false, { nonNullable: true, validators: Validators.required }),
      showOnDashboard: new FormControl(false, { nonNullable: true }),
      showInApi: new FormControl(false, { nonNullable: true, validators: Validators.required }),
      image: new FormControl<File | null>(null, [
        maxFileSizeValidator(this.maxFileSize),
        acceptableFileExtensionsValidator(this.acceptedFileTypes),
      ]),

      contentPerLanguage: new AutoFormArray(
        () =>
          new FormGroup({
            languageCode: new FormControl(),
            languageName: new FormControl(),
            title: new FormControl('', {
              nonNullable: true,
              validators: [Validators.required, Validators.minLength(3), Validators.maxLength(50)],
            }),
            description: new FormControl('', { nonNullable: true, validators: [Validators.required, Validators.maxLength(500)] }),
            buttonText: new FormControl<string | null>(null, Validators.maxLength(25)),
            buttonLinkUrl: new FormControl<string | null>(null, Validators.maxLength(255)),
          })
      ),
    },
    [
      (control: AbstractControl): Record<string, { message: string }> | null => {
        const formGroup = control as FormGroup;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const formValue = formGroup.getRawValue();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (!formValue.showOnDashboard && !formValue.showInApi) {
          formGroup.controls.showOnDashboard.setErrors({ groupErrorMemberAtLeastOneVisibility: true });
          formGroup.controls.showInApi.setErrors({ groupErrorMemberAtLeastOneVisibility: true });
          return {
            atLeasOneVisibility: {
              message: $localize`:@@error.dashboardTileVisibility:Die News muss mindestens in einem Bereich sichtbar sein.`,
            },
          };
        }
        formGroup.controls.showOnDashboard.setErrors(null);
        formGroup.controls.showInApi.setErrors(null);
        return null;
      },
      requiredIfValidator(
        'validFromDate',
        (formGroup) =>
          !!formGroup.controls.expireDate.value && formGroup.controls.validFromDate.value > formGroup.controls.expireDate.value,
        {
          message: $localize`:@@error.validFromEarlierThanExpired:"Gültig von" muss früher sein als "Gültig bis"`,
        },
        true
      ),
      requiredIfValidator(
        'expireDate',
        (formGroup) =>
          !!formGroup.controls.expireDate.value && formGroup.controls.validFromDate.value > formGroup.controls.expireDate.value,
        {
          message: $localize`:@@error.ExpiredLaterThenValidFrom:"Gültig bis" muss später sein als "Gültig vom"`,
        },
        true
      ),
    ]
  );

  fds = new FormDataSource({
    form: this.formGroup,
    loadTrigger$: this.route.paramMap.pipe(map((params) => coerceNumberProperty(params.get('tileId')) || null)),
    loadFn: (tileId): Observable<Partial<DashboardTileDetailDto>> => {
      this.fds.form.disable();
      if (tileId) {
        return combineLatest([
          this.languageService.availableLanguages$,
          httpGetDashboardTileGetDetail(this.http, { query: { id: tileId } }),
        ]).pipe(
          map(
            ([langs, dashboardTile]) =>
              ({
                ...dashboardTile,
                contentPerLanguage: dashboardTile.contentPerLanguage.map(
                  (content) =>
                    ({
                      ...content,
                      languageName: langs.find((l) => l.code == content.languageCode)?.name,
                    }) as ExtendedDashboardTileI18NContent
                ),
              }) as DashboardTileDetailDto
          ),
          tap((dto) => {
            this.tileId = dto.id;
            DashboardTileDetailPage.breadcrumbData$.next(dto.internalName ?? '');
            if (this.canEdit()) {
              this.fds.form.enable();
            }
            this.initialUpdateFile = dto.fileName ? new File([''], dto.fileName) : null;
            this.fds.form.reset({ ...dto, image: this.initialUpdateFile });
          })
        );
      }
      return this.languageService.availableLanguages$.pipe(
        map((availableLanguages) => ({
          contentPerLanguage: availableLanguages.map((availableLang) => {
            return {
              languageCode: availableLang.code,
              languageName: availableLang.name,
            } as ExtendedDashboardTileI18NContent;
          }),
        })),
        tap((t) => {
          if (this.canEdit()) {
            this.fds.form.enable();
          }
          this.fds.form.reset(t);
        })
      );
    },
    saveFn: (formValue, { loadParams: tileId, progressCallback }): Observable<number | void | null> => {
      DashboardTileDetailPage.breadcrumbData$.next(formValue.internalName ?? '');
      if (formValue.image === this.initialUpdateFile) {
        formValue.image = null;
      }
      if (tileId) {
        return httpPostDashboardTileUpdate(
          this.http,
          { body: { ...formValue, id: tileId } },
          { reportProgress: true, observe: 'events' }
        ).pipe(
          watchProgressEmitBody((percent) => {
            progressCallback(percent);
          }, 95)
        );
      } else {
        return httpPostDashboardTileCreate(this.http, { body: formValue }, { reportProgress: true, observe: 'events' }).pipe(
          watchProgressEmitBody((percent) => {
            progressCallback(percent);
          }, 95)
        );
      }
    },
    navigateFn: (ctx) => {
      if (ctx.close) {
        void this.router.navigate(['../'], { relativeTo: this.route });
      } else if (ctx.save) {
        void this.router.navigate(['../', ctx.saveResponse ?? this.tileId], { relativeTo: this.route });
      }
    },
  });

  productDs = new DefaultZvSelectDataSource({
    mode: 'id',
    idKey: 'id',
    labelKey: 'name',
    items: httpGetProductGetProductPicklist(this.http),
  });

  dashboardTileCategoryDs = new DefaultZvSelectDataSource({
    mode: 'id',
    idKey: 'id',
    labelKey: 'name',
    items: dashboardTileCategoryItems,
  });

  priorityDs = new DefaultZvSelectDataSource({
    mode: 'id',
    idKey: 'id',
    labelKey: 'name',
    items: [
      { id: Priority.highest, name: $localize`:@@general.highestPriority:Höchste Priorität` },
      { id: Priority.higher, name: $localize`:@@general.higherPriority:Höhere Priorität` },
      { id: Priority.medium, name: $localize`:@@general.mediumPriority:Mittlere Priorität` },
      { id: Priority.lower, name: $localize`:@@general.lowerPriority:Niedrigere Priorität` },
      { id: Priority.lowest, name: $localize`:@@general.lowestPriority:Niedrigste Priorität` },
    ],
  });

  static getBreadcrumb(): BreadcrumbLabel | Observable<BreadcrumbLabel> {
    return DashboardTileDetailPage.breadcrumbData$.pipe(
      filter((x) => !!x),
      map((name) => $localize`:@@general.changeSomething:${name} bearbeiten`)
    );
  }

  ngOnDestroy(): void {
    DashboardTileDetailPage.breadcrumbData$.next(null);
  }

  get contentPerLanguage(): AutoFormArray {
    return this.formGroup.controls.contentPerLanguage;
  }

  public trackByLangCode(_index: number, item: AbstractControl<DashboardTileInternationalizationContent>) {
    return item.value.languageCode;
  }

  public copyDefaultLangInputsToOtherInputs() {
    this.languageService.copyDefaultLangInputsToOtherInputs(this.formGroup.controls.contentPerLanguage, [
      'title',
      'description',
      'buttonText',
      'buttonLinkUrl',
    ]);
  }
}
