<div class="app-content-management-shell-page__header">
  <h1 class="app-content-management-shell-page__caption" data-cy="pageTitle" i18n="@@general.contentManagement">Content Management</h1>
  <div data-cy="pageSubTitle" i18n="@@contentManagement.subTitle">Sie befinden sich im Content Management Bereich.</div>
</div>

@if (!canEdit()) {
  <div class="app-content-management-shell-page__read-only-info">
    <mat-icon>info</mat-icon>
    <span i18n="@@warning.readOnlyBecauseContentManager"
      >Als zvoove Mitarbeiter haben Sie lediglich Leserechte auf diesen Bereich. Bitte wenden Sie sich an einen Content-Manager, um
      Änderungen vornehmen zu lassen.</span
    >
  </div>
}
@if (navLinks) {
  <zv-navigation-tabs [navLinks]="navLinks">
    <router-outlet></router-outlet>
  </zv-navigation-tabs>
}
