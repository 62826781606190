<div class="app-release-info-shell-page__header">
  <h1 class="app-release-info-shell-page__caption" data-cy="pageTitle" i18n="@@shell.releaseInfo">Release-Informationen</h1>
  <div data-cy="pageSubTitle" i18n="@@changelog.shellDescriptions"
    >Hier finden Sie Informationen zu den Neuerungen und Anpassungen unserer Produktversionen</div
  >
</div>

@if (navLinks$ | async; as navLinks) {
  <zv-navigation-tabs [navLinks]="navLinks">
    <router-outlet></router-outlet>
    @if (!navLinks.length) {
      <div class="app-release-info-shell-page__empty-template" data-cy="noChangelogAvailableText" i18n="@@changelog.noneAvailable">
        Für Sie stehen aktuell keine Release-Informationen zur Verfügung.
      </div>
    }
  </zv-navigation-tabs>
}
