import { AsyncPipe, DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject, OnDestroy } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute } from '@angular/router';
import {
  httpGetServiceAgentDownloadGetDetail,
  httpGetServiceAgentDownloadGetFilterData,
  ServiceAgentDownloadDetailDto,
  urlGetServiceAgentDownloadGetFile,
} from '@zvoove-market/api';
import { backendUrl, BreadcrumbLabel, FormatFileSizePipe, ViewDataSource } from '@zvoove-market/shared';
import { ZvCard } from '@zvoove/components/card';
import { ZvHeader } from '@zvoove/components/header';
import { ZvView } from '@zvoove/components/view';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

interface ExtendedServiceAgentDownloadDetailDto extends ServiceAgentDownloadDetailDto {
  categoryName?: string | null;
  productsString?: string | null;
}

@Component({
  selector: 'app-service-agent-downloads-detail-page',
  templateUrl: './service-agent-downloads-detail.page.html',
  styleUrls: ['./service-agent-downloads-detail.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ZvCard, ZvHeader, AsyncPipe, DatePipe, ZvView, FormatFileSizePipe, MatButtonModule],
})
export class ServiceAgentDownloadsDetailPage implements OnDestroy {
  private http = inject(HttpClient);
  private route = inject(ActivatedRoute);

  private static breadcrumbData$ = new BehaviorSubject<ExtendedServiceAgentDownloadDetailDto | null>(null);
  public downloadUrl$ = this.route.paramMap.pipe(
    map((params) => urlGetServiceAgentDownloadGetFile(backendUrl(), { query: { downloadId: Number(params.get('downloadId') || '') } }))
  );
  public item: ExtendedServiceAgentDownloadDetailDto | null = null;

  ds = new ViewDataSource({
    loadTrigger$: this.route.paramMap.pipe(map((params) => params.get('downloadId') || '')),
    loadFn: (downloadId) =>
      combineLatest([
        httpGetServiceAgentDownloadGetDetail(this.http, { query: { downloadId: Number(downloadId) } }),
        httpGetServiceAgentDownloadGetFilterData(this.http),
      ]).pipe(
        map(([download, downloadFilterData]) => {
          ServiceAgentDownloadsDetailPage.breadcrumbData$.next(download);
          this.item = {
            ...download,
            categoryName: download.category.name,
            productsString: download.productShorthand
              .map((sh) => downloadFilterData.products.find((p) => p.shorthand === sh)?.name ?? sh)
              .join(', '),
          };
        })
      ),
  });

  public static getBreadcrumb(): BreadcrumbLabel | Observable<BreadcrumbLabel> {
    return ServiceAgentDownloadsDetailPage.breadcrumbData$.pipe(
      filter((x) => !!x),
      map((file) => `${file?.title}`)
    );
  }

  ngOnDestroy(): void {
    ServiceAgentDownloadsDetailPage.breadcrumbData$.next(null);
  }
}
