import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { AuthService } from '@zvoove-market/shared';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-dashboard-shell-page',
  templateUrl: './dashboard-shell.page.html',
  styleUrls: ['./dashboard-shell.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterOutlet],
})
export class DashboardShellPage {
  private auth = inject(AuthService);

  public $fullName = computed(() => `${this.auth.$context().firstName} ${this.auth.$context().lastName}`);
}
