import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-payroll-manager-shell-page',
  templateUrl: './payroll-manager-shell.page.html',
  styleUrls: ['./payroll-manager-shell.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterOutlet],
})
export class PayrollManagerShellPage {}
