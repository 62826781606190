import { Routes } from '@angular/router';
import { canAccessCoreBasicsGuard, IBreadcrumbRouteData, getProductNameFromShorthand } from '@zvoove-market/shared';
import { ReleaseInfoShellPage } from './shell/release-info-shell.page';

const getReleaseInfoBreadcrumb = (breadcrumbData: IBreadcrumbRouteData) => {
  const productShorthand = breadcrumbData.paramMap.get('productShorthand');
  return getProductNameFromShorthand(productShorthand);
};

export const releaseInfoRoutes: Routes = [
  {
    path: 'release-info',
    canActivate: [canAccessCoreBasicsGuard()],
    component: ReleaseInfoShellPage,
    data: {
      breadcrumb: () => $localize`:@@shell.releaseInfo:Release-Informationen`,
    },
    children: [
      {
        path: ':productShorthand',
        data: {
          breadcrumb: getReleaseInfoBreadcrumb,
        },
        loadChildren: () => import('./release-info/release-info.module').then((m) => m.ReleaseInfoModule),
      },
    ],
  },
];
