import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-partner-shell-page',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './partner-shell.page.html',
  styleUrls: ['./partner-shell.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerShellPage {}
