import { Routes } from '@angular/router';
import { userCanManageTicketsGuard } from '@zvoove-market/shared';

export const ticketRoutes: Routes = [
  {
    path: 'tickets',
    canActivate: [userCanManageTicketsGuard],
    loadComponent: () => import('./shell/ticket-shell.page').then((c) => c.TicketShellPage),
    data: {
      breadcrumb: () => $localize`:@@general.tickets:Tickets`,
    },
    children: [
      {
        path: '',
        data: {
          breadcrumb: () => $localize`:@@general.overview:Übersicht`,
        },
        loadComponent: () => import('./ticket/list/ticket-list.page').then((c) => c.TicketListPage),
      },
      {
        path: 'create',
        data: {
          breadcrumb: () => $localize`:@@ticket.create:Ticket erstellen`,
        },
        loadComponent: () => import('./ticket/create/ticket-create.page').then((c) => c.TicketCreatePage),
      },
    ],
  },
];
