import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ZvNavigationTabs } from '@zvoove-market/shared';
import { CustomerNavLinkService } from '../service/customerNavLink.service';

@Component({
  selector: 'app-customer-shell-page',
  templateUrl: './customer-shell.page.html',
  styleUrls: ['./customer-shell.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ZvNavigationTabs, RouterOutlet, AsyncPipe],
})
export class CustomerShellPage {
  private navLinkService = inject(CustomerNavLinkService);

  public navLinks$ = this.navLinkService.navLinks$;
}
